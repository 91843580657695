import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 99999999;
  width: 100%;
  text-align: left;
`;

export const Block = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  background: white;
  width: 100%;
  max-width: -webkit-fill-available;
  max-width: -moz-available;
  padding: 15px 50px;
  color: ${({ theme }) => theme.colors.gray.dark};
  box-shadow: ${({ theme }) => theme.shadow.vertical};
  border-radius: ${({ theme }) => theme.borderRadius};

  @media ${({ theme }) => theme.devices.tablet} {
    font-size: ${({ theme }) => theme.fontSize.mm};
    padding: 15px;
  }

  @media ${({ theme }) => theme.devices.mobileL} {
    font-size: ${({ theme }) => theme.fontSize.sm};
    align-self: center;
    text-align-last: center;
    padding: 5px;
  }
`;

export const Content = styled.div`
  margin: auto 0;
  padding: 0 10px;
`;

export const Titulo = styled.div`
  font-weight: bold;
  text-align-last: auto;
`;

export const MainInfo = styled.div`
  text-align-last: auto;

  @media ${({ theme }) => theme.devices.tablet} {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }

  @media ${({ theme }) => theme.devices.mobileL} {
    font-size: 8px;
    align-self: center;
    text-align-last: center;
  }
`;

export const Button = styled.div`
  height: fit-content;
  margin: auto;
  margin-right: 10px;
`;

export const Icon = styled.div`
  margin: auto;
  margin-right: 10px;
  font-size: ${({ theme }) => theme.fontSize.md2};
  color: ${({ theme }) => theme.colors.gray.dark};
`;
