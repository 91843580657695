import React, { useState } from "react";

import PersonalCard from "Assets/Images/Icons/personalCard.png";
import MockCard1 from "Assets/Images/Mocks/storecard1.png";
import MockCard2 from "Assets/Images/Mocks/storecard2.png";
import MockCard3 from "Assets/Images/Mocks/storecard3.png";
import MockCard4 from "Assets/Images/Mocks/storecard4.png";
import ButtonPrimary from "Components/Buttons/Primary";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { GoVerified } from "react-icons/go";
import { Link, useHistory } from "react-router-dom";
import CardHomeStores from "./components/CardHomeStores";
import * as Styled from "./styles";
import useStoresList from "Hooks/Stores/useStoresList";
import StoreItem from "Views/Stores/List/StoreItem";
import NotFoundImage from "../../../Assets/Images/not-found.jpg";
import styled from "styled-components";
const CarouselWrapper = styled.div`
  display: flex;
  max-width: 100%;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
`;

const CarouselContent = styled.div<{ translateX: number }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  grid-template-rows: repeat(3, auto);
  transform: ${({ translateX }) => `translateX(-${translateX}px)`};
  transition: transform 0.3s ease-in-out;
  gap: 16px;
  padding: 12px 44px 12px 44px;
`;

const ArrowButton = styled.button<{ position: "left" | "right" }>`
  position: absolute;
  top: 50%;
  ${({ position }) => (position === "left" ? "left: 0px;" : "right: 0px;")}
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

const StoresHome: React.FC = () => {
  const history = useHistory();
  // TODO: When have stores enough make stores section to home

  const { data: response, isLoading, isError } = useStoresList({
    perPage: 12
  });

  const data =
    response &&
    response.data.sort((a: any, b: any) => {
      return (b.is_highlighted ? 1 : 0) - (a.is_highlighted ? 1 : 0);
    });

  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 6;
  const totalItems = data && data.length >= 6 ? data.length : 6;
  const maxIndex = Math.ceil(totalItems / itemsPerPage) - 1;
  const next = () => {
    if (currentIndex < maxIndex) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const currentItems =
    data && data.length >= 5
      ? data.slice(
          currentIndex * itemsPerPage,
          (currentIndex + 1) * itemsPerPage
        )
      : [];
  return (
    <>
      {data && data.length >= 6 && (
        <Styled.Section>
          <Styled.TopStores>
            <Styled.RightSide>
              <Styled.PersonalCard
                src={PersonalCard}
                alt="Imagem de um card de pessoa"
              />
              <Styled.Title>Lojas Oficiais</Styled.Title>
            </Styled.RightSide>
            <Styled.LeftSide>
              <Styled.ButtonContainer>
                <ButtonPrimary
                  type="button"
                  color="green"
                  action={() => history.push("/lojas-online")}
                  borderRadius="6px"
                >
                  Quero cadastrar minha loja
                </ButtonPrimary>
              </Styled.ButtonContainer>
              <Styled.SeeAllStores>
                <Styled.Link as={Link} to="/stores">
                  Ver todas
                </Styled.Link>
                <FaChevronRight color="#00A859" />
              </Styled.SeeAllStores>
            </Styled.LeftSide>
          </Styled.TopStores>
          <Styled.BodyStores>
            <Styled.RightSideMainBody>
              <CarouselWrapper>
                {data.length > 6 && currentIndex == 1 && (
                  <ArrowButton
                    position="left"
                    onClick={prev}
                    disabled={currentIndex === 0}
                  >
                    <FaChevronLeft size={20} />
                  </ArrowButton>
                )}
                <CarouselContent translateX={0}>
                  {currentItems.map((item: any, index: number) => (
                    <StoreItem
                      key={index}
                      name={item.name}
                      slug={item.slug}
                      banner={item.banner}
                      description={item.description ? item.description : ""}
                    />
                  ))}
                </CarouselContent>
                {data.length > 6 && currentIndex == 0 && (
                  <ArrowButton
                    position="right"
                    onClick={next}
                    disabled={currentIndex == data!.length - 1}
                  >
                    <FaChevronRight size={20} />
                  </ArrowButton>
                )}
              </CarouselWrapper>
            </Styled.RightSideMainBody>
            {/* <Styled.RightSideMainBody>
              {data.slice(1).map((item: any, index: number) => (
                <StoreItem
                  key={index}
                  name={item.name}
                  slug={item.slug}
                  banner={item.banner}
                  description={item.description ? item.description : ""}
                />
              ))}
            </Styled.RightSideMainBody> */}
          </Styled.BodyStores>
        </Styled.Section>
      )}
    </>
  );
};

export default StoresHome;
