import styled from "styled-components";

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.black.normal};
  font-size: ${({ theme }) => theme.fontSize.mm};
  margin: 0 5px;
`;

export const SortContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white.normal};
  border-radius: 5px;
`;

export const SortContainerNew = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: unset;
  outline: #e6e6e6 solid 1px;
  flex-grow: 1;
  display: flex;
  max-width: 85%;
  width: 70%;
`;
