import CurrencyInput from "react-currency-input";
import styled from "styled-components";

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.inter};
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 10px;
`;

export const DetailsLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: 500;
  padding-bottom: 15px;
`;

interface IError {
  valid: boolean;
}

export const Error = styled.span<IError>`
  color: ${({ theme, valid }) =>
    valid ? theme.colors.gray.dark : theme.colors.red.normal};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: ${({ theme }) => theme.fontSize.sm};
  padding-top: 0px;
  height: 10px;
  text-align: right;
  padding-bottom: 20px;
  width: fit-content;
`;
export const CurrencyCustomInput = styled(CurrencyInput)`
  border: none;
  font-family: ${({ theme }) => theme.fonts.inter};
  background: transparent;
  padding-bottom: 10px;
  font-size: ${({ theme }) => theme.fontSize.sm};
  width: 100%;
  transition: border-bottom ${({ theme }) => theme.animation.duration};
  border-bottom: 1px solid  ${({ theme }) => theme.colors.gray.dark},
  color: ${({ theme }) => theme.colors.gray.dark},
  &:focus {
    outline: none;
  }
`;

export const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
`;
