import styled from "styled-components";
import { FaExclamationCircle } from "react-icons/fa";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-grow: 1;
  margin: 3rem 0;
  & div::parent {
    flex-grow: 1;
  }
`;

export const Icon = styled(FaExclamationCircle).attrs(() => ({
  icon: FaExclamationCircle
}))`
  font-size: 100px;
  margin-right: 60px;
  color: ${({ theme }) => theme.colors.gray.dark};
`;

export const Message = styled.p`
  font-weight: 600;
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: ${({ theme }) => theme.fontSize.md};
  line-height: 26px;
`;
