import React, { useCallback, useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { GoVerified } from "react-icons/go";
import styled from "styled-components";

const CarouselContainer = styled.div`
  width: 90vw;
  max-width: ${({ theme }) => theme.siteWidth};
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
`;

const CarouselContainerFlex = styled.div`
  flex: 1;
  margin: 0 auto;
  overflow: hidden;
  margin-top: 30px;
  border-radius: 10px;
  position: relative;
`;
const SlidesWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: translateX(${props => `-${props.translate}%`});
`;

const Slide = styled.a`
  display: block; /* Permite que o slide seja clicável como bloco */
  min-width: 100%;
  height: 320px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ccc;
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  position: relative;
  text-decoration: none;
  color: inherit;

  border-radius: 8px;

  &:before {
    content: "";
    position: absolute;
    inset: 0;
  }
`;

const SlideContent = styled.div`
  position: absolute;
  color: white;
  top: 0;
  right: 0;

  h2 {
    font-size: 24px;
    margin: 0;
    display: -webkit-box; /* Habilita o comportamento de múltiplas linhas */
    -webkit-line-clamp: 6; /* Limita a 6 linhas */
    -webkit-box-orient: vertical; /* Define a orientação vertical */
    overflow: hidden; /* Esconde o texto excedente */
    text-overflow: ellipsis; /* Adiciona as elipses (...) */
  }

  p {
    font-size: 16px;
    margin: 10px 0;
    display: -webkit-box; /* Habilita o comportamento de múltiplas linhas */
    -webkit-line-clamp: 6; /* Limita a 6 linhas */
    -webkit-box-orient: vertical; /* Define a orientação vertical */
    overflow: hidden; /* Esconde o texto excedente */
    text-overflow: ellipsis; /* Adiciona as elipses (...) */
  }
`;

const Controls = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  top: 50%;
  transform: translateY(-50%);
`;

const ArrowButton = styled.button`
  padding: 15px;
  background-color: #1d2311;
  color: #ffffff;
  border-radius: 200px;
  border: none;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  cursor: pointer;
  box-shadow: 0px 0px 11px 0px #00000021;
  z-index: 10;

  &:hover {
    background: #999;
  }

  &:disabled {
    visibility: hidden;
  }
`;

const Dots = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;

  span {
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    opacity: 0.5;

    &.active {
      opacity: 1;
    }
  }
`;
const Badge = styled.div`
  display: inline-flex;
  align-items: center;
  background: #45b062;
  color: white; /* Cor do texto */
  font-size: 12px; /* Tamanho do texto */
  font-family: ${({ theme }) => theme.fonts.inter};
  font-weight: bold;
  padding: 8px 10px; /* Espaçamento interno */
  border-radius: 8px; /* Bordas arredondadas */
  gap: 5px; /* Espaço entre o ícone e o texto */
`;

const CarouselStore = ({ slides, isSponsored, isList }: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = useCallback(() => {
    setCurrentIndex(prev => (prev < slides.length - 1 ? prev + 1 : 0));
  }, [slides.length]);

  const handlePrev = () => {
    setCurrentIndex(prev => (prev > 0 ? prev - 1 : 0));
  };

  const handleDotClick = (index: any) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    if (slides.length <= 1) return;

    const timer = setTimeout(() => {
      handleNext();
    }, 30000);

    return () => clearTimeout(timer);
  }, [currentIndex, handleNext, slides.length]);

  if (isList)
    return (
      <CarouselContainerFlex>
        <SlidesWrapper translate={currentIndex * 100}>
          {slides.map((slide, index) =>
            slide.link ? (
              <Slide
                key={index}
                bgImage={slide.image}
                href={slide.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SlideContent>
                  {isSponsored && !isList && (
                    <Badge style={{ marginBottom: 8 }}>
                      <GoVerified size={15} />
                      PATROCINADO
                    </Badge>
                  )}
                  {/* <h2>{slide.title}</h2>
                  <p>{slide.description}</p> */}
                </SlideContent>
              </Slide>
            ) : (
              <Slide key={index} bgImage={slide.image}>
                <SlideContent>
                  {isSponsored && (
                    <Badge style={{ marginBottom: 8 }}>
                      <GoVerified size={15} />
                      PATROCINADO
                    </Badge>
                  )}
                  {/* <h2>{slide.title}</h2>
                  <p>{slide.description}</p> */}
                </SlideContent>
              </Slide>
            )
          )}
        </SlidesWrapper>
        <Controls>
          <ArrowButton
            style={{ marginLeft: 4 }}
            onClick={handlePrev}
            disabled={currentIndex === 0}
          >
            <FaChevronLeft size={25} />
          </ArrowButton>
          <ArrowButton
            style={{ marginRight: 4 }}
            onClick={handleNext}
            disabled={currentIndex === slides.length - 1}
          >
            <FaChevronRight size={25} />
          </ArrowButton>
        </Controls>
        <Dots>
          {slides.map((_, index) => (
            <span
              key={index}
              className={index === currentIndex ? "active" : ""}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </Dots>
      </CarouselContainerFlex>
    );
  return (
    <CarouselContainer>
      <SlidesWrapper translate={currentIndex * 100}>
        {slides.map((slide, index) =>
          slide.link ? (
            <Slide
              key={index}
              bgImage={slide.image}
              href={slide.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <SlideContent>
                {isSponsored && (
                  <Badge style={{ marginBottom: 8 }}>
                    <GoVerified size={15} />
                    PATROCINADO
                  </Badge>
                )}
                {/* <h2>{slide.title}</h2>
                <p>{slide.description}</p> */}
              </SlideContent>
            </Slide>
          ) : (
            <Slide key={index} bgImage={slide.image}>
              <SlideContent>
                {isSponsored && (
                  <Badge style={{ marginBottom: 8 }}>
                    <GoVerified size={15} />
                    PATROCINADO
                  </Badge>
                )}
                {/* <h2>{slide.title}</h2>
                <p>{slide.description}</p> */}
              </SlideContent>
            </Slide>
          )
        )}
      </SlidesWrapper>
      <Controls>
        <ArrowButton
          style={{ marginLeft: 4 }}
          onClick={handlePrev}
          disabled={currentIndex === 0}
        >
          <FaChevronLeft size={25} />
        </ArrowButton>
        <ArrowButton
          style={{ marginRight: 4 }}
          onClick={handleNext}
          disabled={currentIndex === slides.length - 1}
        >
          <FaChevronRight size={25} />
        </ArrowButton>
      </Controls>
      <Dots>
        {slides.map((_, index) => (
          <span
            key={index}
            className={index === currentIndex ? "active" : ""}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </Dots>
    </CarouselContainer>
  );
};

export default CarouselStore;
