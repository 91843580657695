const PROPERTY_FARM_CATEGORY_ID: string =
  "4365df67-dceb-44f8-b590-32e96edfda83"; // Fazendas;

const PRODUCT_VEHICLE_CATEGORY_ID: string =
  "9b0d113c-82e9-4afd-bd6e-76e2f2500057";

const CONVERTABLE_MEASUREMENT_UNITS = [
  { name: "alqueires", value: "39" },
  { name: "hectares", value: "6" },
  { name: "m²", value: "17" }
];

const VOCATIONS = [
  { name: "Agricultura", value: "a", id: "a" },
  { name: "Pecuária", value: "l", id: "l" },
  { name: "Dupla aptidão", value: "b", id: "b" },
  { name: "Outros", value: "o", id: "o" }
];

const PERSON_TYPES = {
  NATURAL: "Pessoa Física",
  JURIDICAL: "Pessoa Jurídica"
};

const DAYINMILISECONDS = 1000 * 60 * 60 * 24;
const TECHNICAL_ATTRIBUTES = {
  ANO_DE_FABRICACAO: "d8a2c7d9-c9c0-4a20-ad64-e6262f87785f",
  ANO_DO_MODELO: "76288cbb-e77d-4a3c-8f94-0591c8c543c9",
  MARCA: "1c859c10-f529-450c-97a5-6c53c9b70d12",
  COMBUSTIVEL: "2cced295-6bc1-4da5-991a-d7a94e054b97",
  COR: "0f292d06-5394-4471-9b05-9486a6cfd2b9"
};

const GENERAL_SORTINGS = [
  {
    name: "Relevância",
    value: {}
  },
  {
    name: "Menor preço",
    value: {
      sort: "price",
      order: "asc"
    }
  },
  {
    name: "Maior preço",
    value: {
      sort: "price",
      order: "desc"
    }
  }
];

const GENERAL_FILTER = [
  {
    name: "A-Z",
    value: {}
  }
];

const ORDER_STATUS_NAMES = [
  "Pedido Criado",
  "Pagamento Pendente",
  "Pagamento Confirmado",
  "Cancelado",
  "Estornado",
  "Concluído"
];

const SHORT_ORDER_STATUS_NAMES = [
  "Criado",
  "Pendente",
  "Confirmado",
  "Cancelado",
  "Chargeback",
  "Concluído"
];

const INITIAL_CHART_DATE = "2022-11-02";

const SELLER_STATUS_IDS = {
  not_seller: 1, // Can Edit
  processing: 2,
  denied: 3, // Can Edit
  approved: 4
};

const BOLETINS_CATEGORIES = {
  agronegocio: "Agronegócio",
  pecuariaCorte: "Pecuária de Corte",
  pecuariaLeiteira: "Pecuária Leiteira",
  cotacoesDinheiro: "Cotações/Dinheiro",
  negocios: "Negócios",
  geral: "Geral"
};

const BOLETINS_TYPES = {
  audicoes: "Audições",
  recados: "Recados",
  publicidade: "Publicidade"
};

const SHIPPING_STATUSES = {
  pending: 0,
  sent: 1,
  delivered: 2,
  cancelled: 3
};

const SHIPPING_STATUSES_TEXT = {
  "0": "PENDENTE",
  "1": "ENVIADO",
  "2": "ENTREGUE",
  "3": "CANCELADO"
};
const SHIPPING_METHOD_TEXT = {
  ownShipping: "ENVIO PRÓPRIO",
  melhorEnvio: "MELHOR ENVIO"
};

const ORDER_HISTORIC_STATUS = {
  0: "Pedido criado",
  1: "Pagamento pendente",
  2: "Pagamento concluído",
  3: "Embalado",
  4: "Enviado",
  5: "Em rota de entrega",
  6: "Concluído",
  7: "Solicitado cancelado total",
  8: "Solicitado cancelamento parcial",
  9: "Cancelado"
};

const ORDER_STATUS_IDS = {
  created: 1, // Em progresso
  paymentPending: 2, // Em progresso
  paymentConfirmed: 3, // Em progresso
  cancelled: 4, // Cancelado
  chargeback: 5, // Estornado
  complete: 6 // Concluido
};

type StatusId = 1 | 2 | 3 | 4 | 5 | 6;

const ORDER_STATUS_IDS_TRANSLATION: { [key in StatusId]: string } = {
  1: "Pedido Criado",
  2: "Pagamento Pendente",
  3: "Pagamento Confirmado",
  4: "Cancelado",
  5: "Estornado",
  6: "Concluído"
};

enum CHECKOUT_STEP {
  address = "address",
  payment = "payment",
  confirm = "confirm"
}

enum PAYMENT_METHOD {
  credit = "credit"
}
const BANK_ACCOUNT_TYPES = {
  checking: "checking",
  savings: "savings"
};

// Google API
const GOOGLE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ||
  "115042613381-c907lea39013d22p4c86jkrq28gaa69r.apps.googleusercontent.com";
const GOOGLE_CALENDAR_API_KEY =
  process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY ||
  "AIzaSyDUNFZeZ5d1jdU76LbmHEyFtb96eyD7gHw";

export {
  PROPERTY_FARM_CATEGORY_ID,
  PRODUCT_VEHICLE_CATEGORY_ID,
  CONVERTABLE_MEASUREMENT_UNITS,
  VOCATIONS,
  PERSON_TYPES,
  DAYINMILISECONDS,
  TECHNICAL_ATTRIBUTES,
  GENERAL_SORTINGS,
  GENERAL_FILTER,
  ORDER_STATUS_IDS,
  ORDER_STATUS_IDS_TRANSLATION,
  ORDER_STATUS_NAMES,
  SHORT_ORDER_STATUS_NAMES,
  INITIAL_CHART_DATE,
  SELLER_STATUS_IDS,
  ORDER_HISTORIC_STATUS,
  SHIPPING_STATUSES,
  SHIPPING_STATUSES_TEXT,
  SHIPPING_METHOD_TEXT,
  BOLETINS_CATEGORIES,
  BOLETINS_TYPES,
  BANK_ACCOUNT_TYPES,
  GOOGLE_CALENDAR_API_KEY,
  GOOGLE_CLIENT_ID,
  CHECKOUT_STEP,
  PAYMENT_METHOD
};
