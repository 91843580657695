import color from "color";
import styled from "styled-components";

import { FaExclamationTriangle, FaFileAlt, FaTimes } from "react-icons/fa";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: ${props => props.theme.colors.white};
  box-sizing: border-box;
`;

export const Content = styled.div<{ fullWidth?: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  width: 100%;
  overflow: hidden;
  justify-content: center;

  @media ${({ theme }) => theme.devices.tablet} {
    padding: 20px;
    width: calc(100vw - 40px);
    overflow: hidden;
  }
`;

export const InnerPadding = styled.div`
  padding: 0 30px;

  @media ${({ theme }) => theme.devices.tablet} {
    padding: 0;
  }
`;

export const Message = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) =>
    color(theme.colors.green.dark)
      .alpha(0.4)
      .toString()};
`;

export const MessageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white.normal};
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const Warning = styled.div`
  color: ${({ theme }) => theme.colors.orange.normal};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.md};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WarningIcon = styled(FaExclamationTriangle).attrs(props => ({
  icon: FaExclamationTriangle
}))`
  color: ${({ theme }) => theme.colors.orange.normal};
  font-size: 22px;
  padding-right: 10px;
`;

export const FileIcon = styled(FaFileAlt).attrs(props => ({
  icon: FaFileAlt
}))`
  color: ${({ theme }) => theme.colors.green.dark};
  font-size: 42px;
  padding-right: 15px;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.green.dark};
  margin-top: 20px;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.md};
  text-decoration: none;
  text-align: center;
  line-height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
  }

  b {
    color: ${({ theme }) => theme.colors.green.dark};
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.colors.green.light};
    }
  }
`;

export const Close = styled(FaTimes).attrs(props => ({
  icon: FaTimes
}))`
  margin-left: auto;
  font-size: 26px;
  color: ${({ theme }) => theme.colors.white.normal};
  display: flex;
  padding-right: 20px;
  margin-bottom: 20px;
`;
