import styled from "styled-components";

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: unset;
  outline: #e6e6e6 solid 1px;
  flex-grow: 1;
  display: flex;
  max-width: 85%;
  width: 70%;
`;

export const SelectBox = styled.div`
  border: none;
  display: flex;
  flex-direction: row;
  background-color: #FFFFF;
  flex-grow: 1;
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
`;

export const Option = styled.div`
  padding: 10px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;
