import cep from "./Cep";
import cpfcnpj from "./CpfCnpj";
import creditCard from "./CreditCard";
import CreditCardDate from "./CreditCardDate";
import date from "./Date";
import dateV2 from "./DateV2";
import email from "./Email";
import password from "./Password";
import phone from "./PhoneNumber";
import url from "./Url";

export interface IValidation {
  error: string;
  validate: {
    (input: string): boolean;
  };
}

interface IValidators {
  [name: string]: IValidation;
}

const validators: IValidators = {
  email,
  cpfcnpj,
  password,
  cep,
  creditCard,
  CreditCardDate,
  phone,
  url,
  date,
  dateV2
};

export default validators;
