import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  position: relative;
  display: flex;

  flex-direction: column;
  margin-top: 20px;
`;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  // max-height: 1.625em;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.md2};
  font-family: ${({ theme }) => theme.fonts.inter};
  color: #1a1a1a;
  text-transform: capitalize;
`;

export const TitleLink = styled(Link)`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.md2};
  font-family: ${({ theme }) => theme.fonts.inter};
  color: #1a1a1a;
  text-transform: capitalize;
  text-decoration: none;
`;

export const TitleInput = styled.input`
  padding-right: 20px;
  font-weight: 300;
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.green.normal};
  border: none;
  width: auto;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  @media ${({ theme }) => theme.devices.laptop} {
    font-size: ${({ theme }) => theme.fontSize.md2};
    min-width: 600px;
  }
  @media ${({ theme }) => theme.devices.tablet} {
    font-size: ${({ theme }) => theme.fontSize.md2};
  }
`;

export const LineDiv = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const Line = styled.div`
  height: 3.5px;
  background-color: #53b167;
  background-image: linear-gradient(45deg, #53b167 0%, #0e4d03 100%);
  display: flex;
  justify-content: flex-end;
  flex-grow: 96;
  margin-top: 0px;
`;

export const LineBorder = styled.div`
  height: 1.5px;
  border: 1px solid ${({ theme }) => theme.colors.green.normal};
  display: flex;
  justify-content: flex-start;
  flex-grow: 4;
  margin-top: 0px;
`;
