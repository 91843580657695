import React, { createRef, ReactNode, useEffect } from "react";

import * as Styled from "./styles";

interface Props {
  handleClose(value: boolean): void;
  children?: ReactNode;
  title?: string;
  isOpen?: boolean;
  defaultWidth?: string;
}

// TODO: Remake this component to work with isOpen
const Modal: React.FC<Props> = ({
  handleClose,
  children,
  title,
  isOpen,
  defaultWidth
}) => {
  useEffect(() => {
    const close: any = (e: any) => {
      if (e.key === "Escape") {
        handleClose(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [handleClose]);

  const modalRef = createRef<HTMLDivElement>();

  const handleCloseOnClickOut = (e: any) => {
    e.stopPropagation();
    handleClose(false);
  };

  return (
    <>
      <Styled.Container
        className="modal-container"
        tabIndex={0}
        ref={modalRef}
        onClick={handleCloseOnClickOut}
      >
        <Styled.Content
          className="modal-content"
          defaultWidth={defaultWidth}
          onClick={e => e.stopPropagation()}
        >
          <Styled.Header isWithTitle={!!title}>
            {title && <Styled.Title>{title}</Styled.Title>}
            <Styled.Close
              onClick={(e: any) => {
                e.preventDefault();

                handleClose(false);
              }}
            />
          </Styled.Header>
          {children}
        </Styled.Content>
      </Styled.Container>
    </>
  );
};

export default Modal;
