import { useQuery } from "@tanstack/react-query";
import storeList from "Api/stores/list";
import { PartnersRequestList } from "Services/Stores";

export default function useStoresList(params: PartnersRequestList) {
  const query = () => storeList(params);

  return useQuery({
    queryKey: ["storesList", ...Object.values(params)],
    queryFn: query
  });
}
