import React, { useState } from "react";

import storeHighlight from "Api/stores/highlight";
import ButtonPrimary from "Components/Buttons/Primary";
import Form from "Components/Form";
import TextInput from "Components/Form/Inputs/Text";
import Loading from "Components/Loading";
// import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
// import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import useStoreFetch from "Hooks/Stores/useStoreFetch";
// import { FilePond, registerPlugin } from "react-filepond";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import localeActions from "Redux/Locale/actions";
import { AdminRoutes } from "Routes";
import * as Styled from "./styles";

type TParams = {
  slug: string;
};

// registerPlugin(FilePondPluginImageExifOrientation);

const AdminStoresHighlight: React.FC = () => {
  const { slug } = useParams<TParams>();

  const history = useHistory();

  // const [file, setFile] = useState<any>([]);

  const { data: partner } = useStoreFetch({ slug });

  const dispatch = useDispatch();

  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = async (inputs: any) => {
    // if (!partner || !file[0]?.file) {
    if (!partner) {
      dispatch(
        localeActions.addTooltip({
          type: "error",
          message: "Por favor, preencha todos os campos obrigatórios."
        })
      );
      return;
    }

    try {
      const formData = new FormData();

      // formData.append("image", file[0].file);
      formData.append("highlight_start_at", inputs.highlight_start_at);
      formData.append("highlight_expires_at", inputs.highlight_expires_at);

      setIsSaving(true);
      await storeHighlight(partner.id!, formData);

      dispatch(
        localeActions.addTooltip({
          type: "success",
          message: "Loja destacada com sucesso!"
        })
      );
      history.push(AdminRoutes.stores);
    } catch (error) {
      dispatch(
        localeActions.addTooltip({
          type: "error",
          message: "Erro ao tentar destacar loja: " + JSON.stringify(error)
        })
      );
    } finally {
      setIsSaving(false);
    }
  };

  let formattedStartAt = "";
  let formattedExpiresAt = "";
  if (partner) {
    const { highlight_start_at, highlight_expires_at } = partner;

    const formatDate = (isoString: string) => {
      const date = new Date(isoString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };

    formattedStartAt = highlight_start_at ? formatDate(highlight_start_at) : "";
    formattedExpiresAt = highlight_expires_at
      ? formatDate(highlight_expires_at)
      : "";
  }

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Title>Destacar Loja</Styled.Title>
        {partner && (
          <Form submit={onSubmit}>
            <h2>{partner.name}</h2>
            {/* <h4>Definir a imagem de destaque:</h4> */}
            {/* <FilePond
              files={file}
              allowMultiple={false}
              onupdatefiles={setFile}
              storeAsFile={true}
              labelIdle={`
        <div class="content-filepond">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V3zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z"></path></svg>
            <p>Clique ou arraste e solte suas <br/> imagens aqui.</p>
        </div>`}
              credits={false}
            /> */}
            <TextInput
              label="Data de Início"
              name="highlight_start_at"
              placeholder="dd/mm/aaaa"
              mask="99/99/9999"
              defaultValue={formattedStartAt}
              validation="date"
            />
            <TextInput
              label="Data de Expiração"
              name="highlight_expires_at"
              placeholder="dd/mm/aaaa"
              mask="99/99/9999"
              defaultValue={formattedExpiresAt}
              validation="date"
            />
            <Styled.ButtonContainer>
              <div style={{ width: "200px", margin: "auto" }}>
                {isSaving ? (
                  <Loading></Loading>
                ) : (
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px" }}>
                      <ButtonPrimary type="submit">Salvar</ButtonPrimary>
                    </div>
                    <ButtonPrimary
                      color="orange"
                      action={() => history.goBack()}
                    >
                      Cancelar
                    </ButtonPrimary>
                  </div>
                )}
              </div>
            </Styled.ButtonContainer>
          </Form>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};

export default AdminStoresHighlight;
