import React, { useState, useEffect, useCallback } from "react";
import { TError } from "Services/Error/error";
import * as Styled from "./styles";

import api from "Helpers/Api";
import { TState } from "Services/Location/location";
import CheckboxSearch from "Components/Form/Inputs/CheckboxSearch";
import HiddenField from "../Hidden";

export interface IState {
  id: number;
  name: string;
}

export interface IStates extends IState {
  uf: string;
  qty?: number;
}

interface Props {
  name: string;
  setValue(value: any): void;
  label?: string;
  disable?: boolean;
  defaultValue?: TState[];
  segment?: string;
  categories?: string;
  subcategories?: string;
}

const States: React.FC<Props> = ({
  setValue,
  defaultValue = [],
  label = "Estados",
  disable = false,
  name,
  segment,
  categories,
  subcategories
}) => {
  // const location = useLocation();
  // const params = new URLSearchParams(location.search);
  // const stateParams = params.get("estados") || "";
  // const intialState = stateParams.split(",").map(state => parseInt(state));
  const [search, setSearch] = useState("");
  const [states, setStates] = useState<IState[]>([]);
  const [originalStates, setOirginalStates] = useState<IState[]>([]);
  const [selected, setSelected] = useState<IState[]>(defaultValue);
  const [error, setError] = useState<TError>({
    status: false,
    message: ""
  });

  const queryStates = useCallback(async () => {
    try {
      const { data } = await api.get<IStates[]>("/location/states", {
        params: {
          search,
          segment,
          categories,
          subcategories
        }
      });
      setStates(
        data.map((state: TState) => ({
          id: state.id,
          name: state.name,
          qty: state.adsQty
        }))
      );
      setOirginalStates(
        data.map((state: TState) => ({
          id: state.id,
          name: state.name,
          qty: state.adsQty
        }))
      );
      setError({
        status: false,
        message: ""
      });
    } catch (error) {
      if (search.length === 0) {
        setError({
          status: true,
          message: "Nenhum estado pode ser encontrado."
        });
      } else if (
        search.length !== 0 &&
        error.response &&
        error.response.status === 404
      ) {
        setError({
          status: true,
          message: "Nenhum resultado encontrado."
        });
      } else {
        setError({
          status: true,
          message: "Erro no servidor, tente mais tarde."
        });
      }
    }
  }, [search, segment, categories, subcategories]);

  const unselectedStates = states;

  useEffect(() => {
    queryStates();
  }, [queryStates]);

  useEffect(() => {
    setValue(selected);
  }, [selected, setValue]);
  function reorderOptions(selected: any[], stateOptions: any[]) {
    const options = [...originalStates];
    selected.forEach(state => {
      if (
        state.indice !== undefined &&
        state.indice >= 0 &&
        state.indice < options.length
      ) {
        options[state.indice] = state;
      } else {
        options.push(state);
      }
    });
    const remainingOptions = stateOptions.filter(
      option =>
        !options.some(
          (selected: { id: string | number }) => selected.id === option.id
        )
    );

    return options.concat(remainingOptions);
  }

  return (
    <>
      <Styled.Container disable={disable}>
        <CheckboxSearch
          label={label}
          search={search}
          setSearch={setSearch}
          options={unselectedStates}
          selected={selected}
          setSelected={setSelected}
          placeholder="Pesquisar. ."
          showQty={true}
          newSearch={true}
          error={error}
        />
        <HiddenField
          name={name}
          value={selected.map(state => state.id).join(",")}
        />
      </Styled.Container>
    </>
  );
};

export default States;
