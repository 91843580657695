import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: ${({ theme }) => theme.fontSize.mm};
  letter-spacing: 2px;
  font-weight: 600;
  padding-bottom: 10px;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-transform: capitalize;
  cursor: pointer;
  &::after {
    content: "";
    flex: auto;
  }
  font-family: ${({ theme }) => theme.fonts.inter};
`;

export const ErrorMessage = styled.span`
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.red.normal};
  white-space: nowrap;
  margin-left: 0;
  margin-top: 20px;
  @media ${({ theme }) => theme.devices.tablet} {
    margin-top: 20px;
    margin-left: 0;
    text-align: right;
  }
`;
