import { IValidation } from ".";

const Validation: IValidation = {
  error: "Por favor, digite uma data válida",
  validate: (value: string) => {
    const regex = /^(0\d|[12]\d|3[01])\/(0\d|1[0-2])\/\d{4}$/;
    if (!regex.test(value)) {
      return false;
    }
    const [day, month, year] = value.split("/").map(Number);
    const date = new Date(year, month - 1, day);
    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  }
};

export default Validation;
