import api from "Helpers/Api";
import { ProductRequestList, TProduct } from "Services/Ad/Product/product";
import { IAd } from "Services/Ad/ad";
import { IPagination } from "Services/pagination";

export default async function productsList({
  page,
  perPage,
  search,
  categories,
  subcategories,
  vehicles = false,
  published,
  undefinedPrices = true,
  ...rest
}: ProductRequestList) {
  const arr = categories?.split(",").map(c => c.trim()) ?? [];

  if (arr.length > 0 && arr.every(item => item.includes("vazio"))) {
    return {
      data: [],
      total: "0",
      sort: "",
      order: "asc"
    } as any;
  } else {
    categories = arr.filter(item => !item.includes("vazio")).join(",");
    const { data } = await api.get<IPagination<IAd>>("/products/", {
      params: {
        search,
        categories,
        page,
        perPage,
        subcategories,
        vehicles,
        published,
        undefinedPrices,
        ...rest
      }
    });
    return data;
  }
}
