import styled from "styled-components";

export const Container = styled.div`
  margin: 15px 20px;
  display: flex;
  flex-direction: row;
  font-family: ${({ theme }) => theme.fonts.inter};
`;

export const PageLink = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.green.normal};
  font-size: ${({ theme }) => theme.fontSize.mm};
  transition: color ${({ theme }) => theme.animation.duration};
  will-change: color;
  cursor: pointer;
  width: 120px;

  &:last-child {
    text-align: right;
    margin-left: auto;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.green.light};
  }

  @media ${({ theme }) => theme.devices.tablet} {
    width: 50%;
  }
`;

export const PageNumberContainer = styled.div`
  flex-grow: 1;
  text-align: center;
`;

interface IPageNumber {
  active?: boolean;
}

export const PageNumber = styled.span<IPageNumber>`
  cursor: ${({ active }) => (active ? "default" : "pointer")};
  color: ${({ theme, active }) =>
    active ? theme.colors.green.light : theme.colors.gray.dark};
  padding: 0 10px;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.mm};
  &:hover {
    color: ${({ theme }) => theme.colors.green.light};
  }
`;
