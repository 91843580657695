import { useQuery } from "@tanstack/react-query";
import productsList from "Api/products/list";
import { ProductRequestList } from "Services/Ad/Product/product";

export function useProducts(params: ProductRequestList) {
  const query = () => productsList(params);

  console.log("params", params);
  console.log("query", query);

  return useQuery({
    queryKey: ["listProducts", ...Object.values(params)],
    queryFn: query
  });
}
