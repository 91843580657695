import styled from "styled-components";

export const Section = styled.section`
  width: 90vw;
  max-width: ${({ theme }) => theme.siteWidth};
  background: #fff;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  @media ${({ theme }) => theme.devices.mobileL} {
    margin-top: 0px;
  }
`;

export const ButtonContainer = styled.div`
  width: fit-content;

  @media ${({ theme }) => theme.devices.mobileL} {
    display: flex;
    justify-content: center;
    width: 60%;
  }
`;

export const Stores = styled.div`
  display: flex;

  gap: 24px;
`;

export const StoreLogo = styled.img`
  max-width: 200px;
  width: 100%;

  opacity: 0.5;

  @media ${({ theme }) => theme.devices.mobileL} {
    max-width: 150px;
  }
`;

export const Description = styled.p`
  margin: 0;
  padding: 0;

  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.colors.gray.normal};

  @media ${({ theme }) => theme.devices.mobileL} {
    text-align: center;
    max-width: 300px;
    font-size: ${({ theme }) => theme.fontSize.mm};
  }
`;

export const Title = styled.p`
  margin: 0;
  padding: 0;

  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizePx.lg};
  font-family: ${({ theme }) => theme.fonts.inter} !important;
  color: ${({ theme }) => theme.colors.grayNew.gray6};
  line-height: 39px;

  @media ${({ theme }) => theme.devices.mobileL} {
    font-size: ${({ theme }) => theme.fontSizePx.md};
    line-height: 22px;
  }
`;
export const RightSide = styled.div`
  display: flex;
  width: 50%;

  @media ${({ theme }) => theme.devices.mobileL} {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TopStores = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: ${({ theme }) => theme.siteWidth};
  @media ${({ theme }) => theme.devices.mobileL} {
    flex-direction: column;
    width: 100%;
  }
`;

export const PersonalCard = styled.img`
  height: 39px;
  margin-right: 15px;
`;

export const SeeAllStores = styled.a`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.green.normal};
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  margin-left: 24px;
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.green.normal};
`;

export const BodyStores = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  gap: 24px;
  width: 100%;
  max-width: ${({ theme }) => theme.siteWidth};
  @media ${({ theme }) => theme.devices.mobileL} {
    flex-direction: column;
    height: 60vh;
    width: 100%;
    gap: 0;
  }
`;

interface SectionLeftProductsProps {
  backgroundImage: string;
}
export const LeftSideMainBody = styled.div<SectionLeftProductsProps>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 438px;
  width: 100%;
  height: 455px;
  border-radius: 6px;
  position: relative;
  cursor: pointer;

  /* Combina o gradient e a imagem de fundo */
  background: linear-gradient(
      180deg,
      rgba(43, 43, 43, 0) 27.69%,
      #2b2b2b 96.38%
    ),
    ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media ${({ theme }) => theme.devices.tablet} {
    padding: 20px;
  }
`;

export const BodyLeftSideMainBody = styled.div`
  position: absolute;
  bottom: 20%;
  left: 10%;
`;

export const BtnContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 30%;
`;

export const Subtitle = styled.p`
  margin: 0;
  padding: 0;

  color: ${({ theme }) => theme.colors.white.normal};
  font-weight: 600;
  line-height: 30px;
  font-size: ${({ theme }) => theme.fontSize.md2};
  font-family: ${({ theme }) => theme.fonts.inter};
  letter-spacing: -2%;
  max-width: 200px;
`;

export const RightSideMainBody = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  gap: 32px;
  width: 100%;
  justify-content: space-between;

  @media ${({ theme }) => theme.devices.mobileL} {
    width: 100%;
    grid-template-columns: 1fr; /* Apenas uma coluna em telas menores */
    gap: 8px;
  }
`;

export const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media ${({ theme }) => theme.devices.mobileL} {
    flex-direction: column;
    margin-bottom: 0vh;
  }
`;

export const SecondLine = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media ${({ theme }) => theme.devices.mobileL} {
    flex-direction: column;
  }
`;

export const BtnSponsor = styled.button`
  background: ${({ theme }) => theme.colors.green.normal};
  border: none;
  color: ${({ theme }) => theme.colors.white.normal};
  font-size: ${({ theme }) => theme.fontSizePx.smm};
  font-weight: 700;
  padding: 5px 20px;
  border-radius: 8px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 130px;
  margin-bottom: 2vh;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.green.dark};
  }
`;
