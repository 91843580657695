import { useQuery } from "@tanstack/react-query";
import listCategories from "Api/categories/[segment]";
import { IOptions } from "Components/Form/Inputs/CheckboxSearch";
import arrayToString from "Helpers/arrayToString";
import { listCategoriesRequest } from "Services/Category";
import { TError } from "Services/Error/error";
import { useParams } from "react-router-dom";
import products from "../../../Components/Base/Header/Menu/MenuItems/Products";
interface UseCategoriesProps extends listCategoriesRequest {
  selectedCategories: IOptions[];
  initialCategory?: any[];
}

interface Params {
  category: string;
}
const menuProducts: any[] =
  products.submenu && products.submenu.length > 0 ? products.submenu : [];

export default function useCategories({
  selectedCategories,
  ...queryParams
}: UseCategoriesProps) {
  const { category: categoryParams = "" } = useParams<Params>();

  const query = () => listCategories(queryParams);

  const { data, isError } = useQuery({
    queryKey: ["listCategories", ...Object.values(queryParams)],
    queryFn: query,
    keepPreviousData: true
  });
  // const formattedMenuProducts = menuProducts
  //   .map((item, index) => ({
  //     id: index + "vazio",
  //     name: item.category,
  //     subvalue: "",
  //     qty: "1"
  //   }))
  //   .filter(item => {
  //     return queryParams.search
  //       ? item.name.toLowerCase().includes(queryParams.search.toLowerCase())
  //       : true;
  //   });

  const categoriesError: TError = {
    status: isError,
    message: isError ? "Erro no servidor, tente novamente mais tarde" : ""
  };
  // const dataFormated = data
  //   ? data.map(
  //       (item): IOptions => ({
  //         id: item.id,
  //         name: item.title,
  //         subvalue: item.slug,
  //         qty: item.adsQty
  //       })
  //     )
  //   : [];
  // let categoriesOptions: any[] = [];
  // if (queryParams.segment == "products") {
  //   categoriesOptions = formattedMenuProducts.map(item => {
  //     const matchingItem = dataFormated.find(e => e.name == item.name);
  //     return matchingItem || item;
  //   });
  // } else {
  //   categoriesOptions = dataFormated;
  // }

  let categoriesOptions: any[] = data
    ? data.map(
        (item): IOptions => ({
          id: item.id,
          name: item.title,
          subvalue: item.slug,
          qty: item.adsQty
        })
      )
    : // .filter(
      //   category =>
      //     selectedCategories.filter(
      //       (item: IOptions) => item.id === category.id
      //     ).length === 0
      // )
      [];

  const categoryByUrl = data && data.find(item => item.slug === categoryParams);

  const categoriesFilter = categoryByUrl
    ? categoryByUrl.id
    : categoryParams
    ? "vazio"
    : arrayToString(selectedCategories);

  return {
    categoriesFilter,
    categoryByUrl,
    categoriesOptions,
    categoriesError
  };
}
