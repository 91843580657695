import { FaSearch } from "react-icons/fa";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  max-width: ${({ theme }) => theme.siteWidth};
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
`;

export const Header = styled.div`
  position: relative;
`;

export const Info = styled.span`
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.gray.dark};
  margin: auto;
  text-align: start;
  width: 60%;
  padding: 10px;

  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
  }
`;

export const Search = styled.input`
  border: none;
  padding: 0px 25px;
  height: auto;
  max-height: 60px;
  min-height: 48px;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.mm};
  font-family: ${({ theme }) => theme.fonts.inter};
  border: 1px solid ${({ theme }) => theme.colors.grayNew.gray1};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
`;

export const SearchIcon = styled(FaSearch)`
  font-size: 19px;
  margin: 0 auto;
  font-weight: thin;
  color: ${props => props.theme.colors.white.normal};
`;

export const SearchBtn = styled.button`
  background-color: ${({ theme }) => theme.colors.green.normal};
  border: none;
  color: ${({ theme }) => theme.colors.white.normal};
  margin: 0;
  padding: 0 20px;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 10px;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
`;

export const FormContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white.normal};
  display: flex;
  max-height: 60px;
  width: 40%;
  flex-direction: row;
  border-radius: 5px;
  border: none;
`;
export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;

  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.green.normal};
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.md};
  margin: 30px 0 20px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.green.normal};
`;

export const ArticlesContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: row;
  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;
