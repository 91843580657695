import styled from "styled-components";

export const PartnersContainers = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  align-items: center;

  margin: 0px 0 0 0;
`;
export const PartnersTitle = styled.p`
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: ${({ theme }) => theme.fontSize.md2};
  line-height: 29px;
`;

export const PartnersContent = styled.div`
  display: flex;
  gap: 56px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;

  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
    margin-bottom: 48px;
  }
`;

export const CEIALogo = styled.img`
  max-width: 240px;
  max-height: 140px;
`;
export const UFGLogo = styled.img`
  max-width: 180px;
  max-height: 100px;
`;

export const EmbrapiiLogo = styled.img`
  max-width: 180px;
  max-height: 80px;
`;
export const SebraeLogo = styled.img`
  max-width: 180px;
  max-height: 100px;
`;
