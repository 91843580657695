export enum Account {
  home = "/minha-conta",
  properties = "/minha-conta/imoveis",
  leases = "/minha-conta/arrendamentos",
  products = "/minha-conta/produtos",
  vehicles = "/minha-conta/veiculos",
  jobs = "/minha-conta/vagas",
  services = "/minha-conta/serviços",
  integration = "/minha-conta/integracao",
  edit = "/minha-conta/editar/",
  infos = "/minha-conta/editar/informacoes-gerais",
  privacity = "/minha-conta/editar/privacidade-segurança",
  adress = "/minha-conta/editar/meus-enderecos",
  sellerData = "/minha-conta/editar/dados-vendedor",
  sellerBusinessAddress = "/minha-conta/editar/endereco-comercial",
  sellerBankData = "/minha-conta/editar/dados-bancarios",
  sellerBeSeller = "/minha-conta/editar/ser-um-vendedor",
  payment = "/minha-conta/editar/meios-de-pagamento",
  sell = "/minha-conta/venda/",
  sellDetails = "/minha-conta/venda/:sellId",
  order = "/minha-conta/pedido/",
  adPlan = "/minha-conta/adplans/",
  orderDetails = "/minha-conta/pedido/:orderId"
}

export enum Auth {
  login = "/login/",
  registration = "/login/registration/",
  emailConfirmation = "/login/registration/confirmation/"
}

enum Routes {
  /* auth Routes */
  base = "*",
  login = "/login/",
  registration = "/login/registration/",
  registered = "/login/registration/registered/",
  emailConfirmation = "/login/registration/confirmation/",
  socialRegistration = "/login/socialRegistration/",
  forgotPassword = "/login/esqueci-minha-senha/",
  sendForgotPassword = "/login/esqueci-minha-senha/enviado/",
  newPassword = "/login/esqueci-minha-senha/nova-senha/",
  newPasswordSuccess = "/login/esqueci-minha-senha/nova-senha/sucesso/",
  enable = "/login/habilitar/",
  sendEnable = "/login/habilitar/enviado/",
  enableToken = "/login/habilitar/token/",

  /* Ads edit routes */
  adCreateEdit = "/anuncio/criar-editar/:segment/:page/:slug?",
  adCreateEditGeneralInformation = "/anuncio/criar-editar/:segment/informacoes-gerais/:slug?",
  adCreateEditLocalization = "/anuncio/criar-editar/:segment/localizacao/:slug?",
  adCreateEditPriceStockAndSize = "/anuncio/criar-editar/:segment/preco-estoque/:slug?",
  adCreateEditContact = "/anuncio/criar-editar/:segment/contato/:slug?",
  adCreateEditImages = "/anuncio/criar-editar/:segment/imagens/:slug?",
  adCreateEditDeliveryAddress = "/anuncio/criar-editar/:segment/entrega-endereco/:slug?",
  adCreateEditDeliveryOwnShipping = "/anuncio/criar-editar/:segment/entrega-propria/:slug?",
  adCreateEditDeliveryMelhorEnvio = "/anuncio/criar-editar/:segment/entrega-terceirizada/:slug?",

  // adEdit = "/ad/:slug/edit",

  /*No segment Routes*/
  search = "/busca/:category?",

  /* Jobs Routes */
  jobs = "/vagas/",
  job = "/vagas/:slug",

  /* Services Routes */
  services = "/servicos/",
  service = "/servico/:slug",

  /* Properties Routes*/
  properties = "/imoveis/:category?",
  property = "/imovel/:slug",

  /* Leases Routes*/
  leases = "/arrendamentos/:category?",
  lease = "/arrendamento/:slug",

  /* Products Routes*/
  productsWithCategory = "/produtos/:category?/:subcategory?",
  products = "/secoes-produtos/",
  product = "/produto/:slug",

  /* Vehicles Routes*/
  vehicles = "/veiculos/:subcategory?",
  vehicle = "/veiculo/:slug",

  /* Blog Routes*/
  articles = "/noticias/:category?",
  article = "/noticia/:slug",

  /* Paineis Power BI */
  powerbi = "/agro-boletins",

  /* Not Found*/
  notFound = "/404",

  /* Home*/
  home = "/",

  /* Quotations*/
  quotations = "/cotações",

  /* Home*/
  fairs = "/feiras",

  /* Footer Pages*/
  aboutUs = "/informacoes/sobre-nos/",
  useConditions = "/informacoes/termos-condicoes/",
  privacityConditions = "/informacoes/privacidade-confidencialidade/",
  securityConditions = "/informacoes/seguranca/",
  cookieConditions = "/informacoes/cookies/",
  dataOwnConditions = "/informacoes/titulares-dados/",
  announceProducts = "/informacoes/anuncie-produtos/",
  publicity = "/informacoes/Publicidade/",
  publishServices = "/informacoes/publique-servicos/",
  workWithUs = "/informacoes/trabalhe-conosco/",
  devolutionConditions = "/informacoes/politica-devolucao/",
  paymentConditions = "/informacoes/meios-pagamento/",
  deliveryConditions = "/informacoes/politica-entrega/",
  talkToUs = "/informacoes/fale-conosco/",
  partnerConditions = "/informacoes/seja-parceiro/",
  pricesConditions = "/informacoes/politica-precos/",
  earnMoneyConditions = "/informacoes/ganhe-dinheiro/",
  sellWithUs = "/informacoes/venda-conosco/",

  termStores = "/informacoes/termo-lojas-online",

  /* Cart Pages*/
  cart = "/carrinho/",

  /* Checkout Pages*/
  checkout = "/checkout/",
  subscription = "/checkout/subscription",
  checkoutAdplan = "/checkout/adplan/:slug",

  /* Redirect URI OAuth MelhorEnvio */

  bestSend = "/oauth/melhorenvio",

  boletins = "/boletins",

  stores = "/stores",
  store = "/stores/:slug",
  storeAnnoucement = "/lojas-online",

  adplans = "/adplans",

  campaignsContacts = "/campanhas-contato"
}

export enum AdminRoutes {
  admin = "/admin/*",
  login = "/admin/login",
  fairs = "/admin/fairs",
  fairsEdit = "/admin/fairs/:fairId",
  fairsCreate = "/admin/fair/create",
  articles = "/admin/articles",
  articleCreate = "/admin/article/create",
  articleEdit = "/admin/articles/:articleId",
  campaigns = "/admin/campaigns",
  campaignsEdit = "/admin/campaigns/edit/:campaignId",
  campaignsCreate = "/admin/campaign/create",
  banners = "/admin/banners",
  bannersEdit = "/admin/banners/edit/:bannerId",
  bannersCreate = "/admin/banners/create",
  stores = "/admin/stores",
  storesHighlight = "/admin/stores/highlight/:slug",
  products = "/admin/products",
  productsHighlight = "/admin/products/highlight/:slug"
}

export default Routes;
