import Logo from "Components/Base/Header/Logo";
import React from "react";
import { BiNews } from "react-icons/bi";
import { BsCalendarDate, BsFilterSquare, BsMegaphone } from "react-icons/bs";
import { useHistory, useLocation } from "react-router-dom";
import { AdminRoutes } from "Routes";
import * as Styled from "./styles";

const Routes = [
  {
    name: "Feiras",
    icon: <BsCalendarDate />,
    route: "/admin/fairs"
  },
  {
    name: "Notícias",
    icon: <BiNews />,
    route: "/admin/articles"
  },
  {
    name: "Campanhas",
    icon: <BsMegaphone />,
    route: "/admin/campaigns"
  },
  {
    name: "Banners",
    icon: <BsFilterSquare />,
    route: "/admin/banners"
  },
  {
    name: "Lojas",
    icon: <BsFilterSquare />,
    route: AdminRoutes.stores
  },
  {
    name: "Produtos",
    icon: <BsFilterSquare />,
    route: AdminRoutes.products
  }
];

const Sidebar: React.FC = () => {
  const history = useHistory();

  let location = useLocation();

  return (
    <Styled.Container>
      <Styled.LogoContainer>
        <Logo />
        <Styled.LogoLine />
      </Styled.LogoContainer>
      <Styled.Stack>
        {Routes.map((route, index) => (
          <Styled.RouteBox
            key={index}
            isActive={location.pathname.includes(route.route)}
            onClick={() => history.push(route.route)}
          >
            {route.icon}
            <p>{route.name}</p>
          </Styled.RouteBox>
        ))}
      </Styled.Stack>
    </Styled.Container>
  );
};

export default Sidebar;
