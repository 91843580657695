import bannerCreate from "Api/banners/create";
import bannerDelete from "Api/banners/delete";
import bannerUpdateImage from "Api/banners/updateImage";
import ButtonPrimary from "Components/Buttons/Primary";
import Form from "Components/Form";
import CheckBox from "Components/Form/Inputs/CheckBox";
import Select from "Components/Form/Inputs/Select";
import TextInput from "Components/Form/Inputs/Text";
import localeActions from "Redux/Locale/actions";
import { IBannerRequest } from "Services/Banners/banner";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import moment from "moment";
import React, { useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { bannerPositions } from "..";
import * as Styled from "./styles";

const simpleDateFormat = "DD/MM/YYYY hh:mm";

registerPlugin(FilePondPluginImageExifOrientation);
registerPlugin(FilePondPluginFileValidateSize);

const AdminBannersCreate: React.FC = () => {
  const history = useHistory();

  const [file, setFile] = useState<any>([]);

  const dispatch = useDispatch();

  const onFormSubmit = async (bannerRequest: IBannerRequest) => {
    const startAtDateUpdated = moment(
      bannerRequest.start_at,
      simpleDateFormat
    ).utc(true);
    const expiresAtDateUpdated = moment(
      bannerRequest.expires_at,
      simpleDateFormat
    ).utc(true);

    if (
      !bannerRequest.title ||
      !bannerRequest.description ||
      !bannerRequest.campaign_code ||
      !bannerRequest.link ||
      !bannerRequest.position ||
      bannerRequest.start_at.indexOf("_") !== -1 ||
      !startAtDateUpdated ||
      bannerRequest.expires_at.indexOf("_") !== -1 ||
      !expiresAtDateUpdated ||
      !file[0]?.file
    ) {
      dispatch(
        localeActions.addTooltip({
          type: "error",
          message: "Por favor, preencha todos os campos obrigatórios."
        })
      );
      return;
    }

    bannerRequest.start_at = startAtDateUpdated.toISOString();
    bannerRequest.expires_at = expiresAtDateUpdated.toISOString();

    let response;
    try {
      response = await bannerCreate(bannerRequest);
    } catch (error) {
      dispatch(
        localeActions.addTooltip({
          type: "error",
          message: "Erro ao tentar criar banner"
        })
      );
    }

    if (response.data.id) {
      try {
        const formData = new FormData();
        formData.append("image", file[0].file);
        await bannerUpdateImage(response.data.id, formData);
        dispatch(
          localeActions.addTooltip({
            type: "success",
            message: "Banner criado com sucesso!"
          })
        );
        history.push(`/admin/banners`);
      } catch (error) {
        await bannerDelete(response.data.id);
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message: "Erro ao tentar criar imagem do banner"
          })
        );
      }
    }
  };
  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Title>Criar Banner</Styled.Title>
        <Form key={"form"} submit={onFormSubmit}>
          <TextInput
            label="Título"
            name="title"
            placeholder="Digite o título do banner"
          />

          <TextInput
            label="Descriçao"
            name="description"
            placeholder="Digite a descrição do banner"
          />

          <TextInput
            label="Código da Campanha*"
            name="campaign_code"
            placeholder="Digite o código da campanha"
            required
          />

          <TextInput
            label="Link"
            name="link"
            placeholder="Digite o link do banner"
          />

          <Select
            label="Posição do Banner"
            name="position"
            options={bannerPositions}
            defaultValue={bannerPositions[0].value}
          />

          <TextInput
            label="Data de Início"
            name="start_at"
            placeholder="dd/mm/aaaa hh:mm"
            mask="99/99/9999 99:99"
          />

          <TextInput
            label="Data de Expiração"
            name="expires_at"
            placeholder="dd/mm/aaaa"
            mask="99/99/9999 99:99"
          />

          <CheckBox label="Ativo" name="enabled">
            Ativo
          </CheckBox>

          <Styled.FormRow>
            <FilePond
              files={file}
              allowMultiple={false}
              onupdatefiles={setFile}
              storeAsFile={true}
              maxFileSize="5MB"
              labelIdle={`
        <div class="content-filepond">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V3zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z"></path></svg>
            <p>Clique ou arraste e solte suas <br/> imagens aqui.</p>
        </div>`}
              credits={false}
            />
          </Styled.FormRow>

          <Styled.ButtonContainer>
            <ButtonPrimary type="submit" color="orange">
              Criar
            </ButtonPrimary>
          </Styled.ButtonContainer>
        </Form>
      </Styled.Content>
    </Styled.Container>
  );
};

export default AdminBannersCreate;
