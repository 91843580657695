import Routes from "Routes";
import React from "react";
import { TbAlertCircle } from "react-icons/tb";
import { IMenuItemModel } from "../index";

const menu: IMenuItemModel = {
  name: "Destaques",
  link: Routes.products,
  icon: <TbAlertCircle />
};

export default menu;
