import Keyboard from "@material-ui/icons/KeyboardArrowDown";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import styled from "styled-components";
import { ISelectTheme } from "./theme";
interface IContainer {
  width: number;
  textAlign: ISelectTheme["textAlign"];
}
export const Container = styled.div<IContainer>`
  min-width: ${({ width }) => width}px;
  border-right-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  text-align: ${({ textAlign }) => (textAlign ? `center` : `unset`)};
  cursor: pointer;
  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
  }
`;
export const ContainerNew = styled.div<IContainer>`
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: unset;
  outline: #e6e6e6 solid 1px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  text-align: center;
  cursor: pointer;
`;
export const Message = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm};
  padding-bottom: 2px;
  color: ${({ theme }) => theme.colors.gray.dark};
  text-align: right;
`;
interface ISelected {
  background: ISelectTheme["background"];
  brightness: ISelectTheme["brightness"];
  border: ISelectTheme["border"];
  disabled: boolean;
}
export const SelectedNew = styled.div<ISelected>`
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  border: none;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme, background }) =>
    background === "gray"
      ? theme.colors[background].light
      : theme.colors[background].normal};
  flex-grow: 1;
`;
export const Selected = styled.div<ISelected>`
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  border: none;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme, background }) =>
    background === "gray"
      ? theme.colors[background].light
      : theme.colors[background].normal};
  border-radius: ${({ theme }) =>
    `${theme.borderRadius} 0 0 ${theme.borderRadius}`};
  flex-grow: 1;
`;
interface ILabel {
  colorLabel: ISelectTheme["colorLabel"];
  justify: ISelectTheme["justify"];
}
export const Label = styled.div<ILabel>`
  flex-grow: 1;
  font-size: ${({ theme }) => theme.fontSizePx.mm};
  line-height: 24px;
  padding: 5px;
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ colorLabel, theme }) =>
    colorLabel === "gray"
      ? theme.colors[colorLabel].dark
      : theme.colors[colorLabel].normal};
  font-weight: 500;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: ${({ justify }) => `${justify}`};
`;
export const LabelNew = styled.div<ILabel>`
  flex-grow: 1;
  font-size: 16px;
  line-height: 24px;
  font-family: ${({ theme }) => theme.fonts.inter};
  color: #1a1a1a;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;
interface IExpand {
  expandColor: ISelectTheme["expandColor"];
}
export const Expand = styled.div<IExpand>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ theme, expandColor }) => theme.colors[expandColor].dark};
  color: ${({ theme, expandColor }) => theme.colors[expandColor].dark};
  margin: 4px 0;
  padding: 0 5px;
`;
export const UnfoldMoreNew = styled(Keyboard)``;
interface IOptions {
  expand: boolean;
  textColor: ISelectTheme["textColor"];
  scroll: ISelectTheme["scroll"];
}
export const UnfoldMore = styled(UnfoldMoreIcon)``;
interface IOptions {
  expand: boolean;
  textColor: ISelectTheme["textColor"];
  scroll: ISelectTheme["scroll"];
  optionsNumber?: number;
}
export const Options = styled.div<IOptions>`
  max-height: ${({ expand }) => (expand ? "200px" : "0px")};
  color: ${({ textColor, theme }) => theme.colors[textColor].normal};
  z-index: 1;
  overflow-y: ${({ expand }) => (expand ? "auto" : "hidden")};
  position: absolute;
  top: calc(100%);
  left: 0;
  outline: ${({ expand }) => (expand ? "#e6e6e6 solid 1px" : "none")};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: ${({ theme }) => theme.fontSizePx.mm};
  right: 0;
  transition: max-height ${({ theme }) => theme.animation.duration};
  &::-webkit-scrollbar {
    display: ${({ scroll }) => (scroll ? "none" : "none")};
  }
  border-radius: ${({ theme }) =>
    `0 0 ${theme.borderRadius} ${theme.borderRadius}`};
`;
export const OptionsNew = styled.div<IOptions>`
  max-height: ${({ expand }) => (expand ? "200px" : "0px")};
  color: ${({ textColor, theme }) => theme.colors[textColor].normal};
  z-index: 1;
  overflow-y: ${({ expand }) => (expand ? "auto" : "hidden")};
  position: absolute;
  top: calc(100%);
  left: 0;
  outline: ${({ expand }) => (expand ? "#e6e6e6 solid 1px" : "none")};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: ${({ theme }) => theme.fontSizePx.mm};
  right: 0;
  ${({ optionsNumber, theme }) =>
    optionsNumber && optionsNumber > 1
      ? `transition: max-height ${theme.animation.duration};`
      : ""}
  &::-webkit-scrollbar {
    display: ${({ scroll }) => (scroll ? "none" : "none")};
  }
  border-radius: ${({ theme }) =>
    `0 0 ${theme.borderRadius} ${theme.borderRadius}`};
`;
interface IOption {
  background: ISelectTheme["backgroundOption"];
  brightness: ISelectTheme["brightnessOption"];
  hover: ISelectTheme["hover"];
  border: ISelectTheme["borderOption"];
}
export const Option = styled.div<IOption>`
  padding: 5px;
  border-bottom: ${({ border }) => border && `1px solid`};
  background-color: ${({ theme, background }) =>
    theme.colors[background].light};
  @media ${({ theme }) => theme.devices.laptop} {
    &:hover {
      background-color: ${({ hover, theme }) => theme.colors[hover].normal};
    }
  }
`;
interface IError {
  valid: boolean;
}
export const Error = styled.span<IError>`
  color: ${({ theme, valid }) =>
    valid ? theme.colors.gray.dark : theme.colors.red.normal};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: ${({ theme }) => theme.fontSize.mm};
  padding-top: 5px;
  height: 10px;
  text-align: right;
`;
export const SchoolingLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: ${({ theme }) => theme.fontSize.mm};
  letter-spacing: 2px;
  font-weight: 600;
  padding-bottom: 10px;
`;
