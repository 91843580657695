import React from "react";
import { TbGraph } from "react-icons/tb";
import Routes from "Routes";
import { IMenuItemModel } from "../index";

const menu: IMenuItemModel = {
  name: "Cotações",
  icon: <TbGraph />,
  link: Routes.quotations
};

export default menu;
