import React from "react";
import { LuStore } from "react-icons/lu";
import Routes from "Routes";
import { IMenuItemModel } from "../index";

const menu: IMenuItemModel = {
  name: "Lojas",
  icon: <LuStore />,
  link: Routes.stores
};

export default menu;
