import styled from "styled-components";

export const Container = styled.div`
  padding-top: 25px;
`;

export const Title = styled.span`
  padding-left: 20px;
  font-weight: 300;
  font-size: ${({ theme }) => theme.fontSize.md2};
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.green.normal};
  font-weight: 500;
  @media ${({ theme }) => theme.devices.tablet} {
    font-size: ${({ theme }) => theme.fontSize.md2};
  }
`;

export const SearchTitleBox = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius};
`;
