import styled from "styled-components";

export const Container = styled.div`
  display: flex;

  width: 100%;
  height: 100vh;

  justify-content: center;

  background-color: ${({ theme }) => theme.colors.gray.light};

  margin: 64px 24px;

  border-radius: 12px;
`;

export const Content = styled.div`
  display: flex;

  flex-direction: column;

  background-color: ${({ theme }) => theme.colors.white.normal};
  width: 100%;
  height: fit-content;

  padding: 16px;
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.black.normal};
  font-weight: 700;
  margin-bottom: 2rem;
`;

export const List = styled.div`
  display: flex;

  margin-top: 16px;

  flex-direction: column;

  gap: 16px;
`;

export const SearchBox = styled.div`
  width: 100%;

  input {
    height: 40px;
  }

  svg {
    box-sizing: unset;
  }
`;

export const Form = styled.div`
  display: flex;

  flex-direction: column;

  gap: 16px;
`;

export const RangeDate = styled.div`
  display: flex;

  max-width: 320px;
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const LocalizationContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;

export const Item = styled.div`
  display: flex;
  width: 50%;
  padding-right: 87px;
  &:last-child {
    padding-right: 0px;
  }
  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
    padding-right: 87px;
    display: unset;
  }
`;

export const InputTitle = styled.h2`
  font-size: 1rem;

  font-weight: normal;
`;

export const ImageContainer = styled.div`
  display: flex;

  flex-direction: column;

  gap: 12px;
`;

export const Preview = styled.img`
  width: 100%;

  max-width: 240px;
`;
export const PublicationDate = styled.div`
  display: flex;

  flex-direction: column;

  gap: 6px;

  margin: 12px 0;
`;

export const PublicationLabel = styled.p`
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: ${({ theme }) => theme.fontSize.mm};
  letter-spacing: 2px;
  font-weight: 600;
  padding-bottom: 10px;

  margin: 0;
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;

  .filepond--wrapper {
    width: 100%;
  }
  .filepond--panel-root {
    background: #fff;

    border: 1px dashed #a0aec0;
  }
  .filepond--drop-label label {
    width: 100%;
    cursor: pointer;
  }
  .content-filepond {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
  }
  .content-filepond p {
    margin: 0 auto;
  }
  .content-filepond svg {
    margin-left: 32px;
    font-size: var(--chakra-fontSizes-4xl);
    color: var(--chakra-colors-green-700);
  }
`;
