import styled from "styled-components";

export const Field = styled.fieldset`
  border: none;
  padding: 0;

  .react-calendar {
    position: absolute;
    z-index: 9999;
  }

  .react-date-picker__wrapper {
    border: none;
    border-bottom: thin solid #58595b;

    font-family: dinpro, sans-serif;
  }

  .react-date-picker__inputGroup {
    min-width: 0;
  }

  .react-date-picker__button {
    padding: 4px 2px;
  }
`;

export const Calendar = styled.div`
  display: fixed;
  z-index: 1;
`;

export const Title = styled.label`
  display: flex;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: 600;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.green.dark};
  margin-bottom: 20px;
`;

export const Labels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Label = styled.span`
  font-weight: normal;
  margin-right: 10px;
  letter-spacing: 1px;
  font-size: ${({ theme }) => theme.fontSize.sm};
`;

export const DateTile = styled.span`
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.gray.dark};
  letter-spacing: 1px;
  font-weight: 600;

  display: block;
  margin-bottom: 0.5rem;
`;
