import React from "react";
import { LuCalendar } from "react-icons/lu";
import Routes from "Routes";
import { IMenuItemModel } from "../index";

const menu: IMenuItemModel = {
  name: "Feiras",
  icon: <LuCalendar />,
  link: Routes.fairs
};

export default menu;
