import { useQuery } from "@tanstack/react-query";
import bannerFetch from "Api/banners/fetch";

export default function useBanner(bannerId: string) {
  const query = () => bannerFetch(bannerId).then(response => response?.data);

  return useQuery({
    queryKey: [bannerId],
    queryFn: query,
    keepPreviousData: true
  });
}
