import api from "Helpers/Api";
import { IAd } from "Services/Ad/ad";
import { TSegment } from "Services/Segment/segment";

export interface FetchProductRequest {
  segment: TSegment;
  slug: string;
  userId?: string;
  gaId?: string | null;
}

export default async function fetchProduct({
  slug,
  segment,
  ...params
}: FetchProductRequest) {
  const url = `/${segment.segment}/${slug}`;

  const { data } = await api.get<IAd>(url, { params });

  return data;
}
