import ButtonPrimary from "Components/Buttons/Primary";
import Loading from "Components/Loading";
import Pagination2 from "Components/Pagination2";

import useBanners from "Hooks/Banners/useBanners";
import { AdminRoutes } from "Routes";
import React, { useState } from "react";
import { useHistory } from "react-router";
import BannerItem from "./Banner";
import * as Styled from "./styles";

export const bannerPositions = [
  {
    value: "banner_lateral",
    name: "Banner Lateral"
  },
  {
    value: "meio_home",
    name: "Meio Home"
  },
  {
    value: "meio_produtos",
    name: "Meio Produtos"
  },
  {
    value: "topo_lojaoficial",
    name: "Topo Loja Oficial"
  }
];

const AdminBanners: React.FC = () => {
  const [page, setPage] = useState(1);
  const history = useHistory();
  const { data: bannersListResponse, isLoading, refetch } = useBanners();

  const handleChangePage = (page: number) => setPage(page);

  const handleBannerChange = () => refetch();

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Title>Banners</Styled.Title>
        {isLoading ? (
          <Loading />
        ) : (
          <Styled.List>
            <ButtonPrimary
              color="green"
              action={() => history.push(AdminRoutes.bannersCreate)}
            >
              Adicionar banner
            </ButtonPrimary>

            {bannersListResponse?.data.map(banner => (
              <BannerItem
                key={banner.id}
                banner={banner}
                onBannerChange={handleBannerChange}
              />
            ))}
            <Pagination2
              lastPage={bannersListResponse?.lastPage ?? 1}
              onPageChange={handleChangePage}
              page={page}
              perPage={5}
            />
          </Styled.List>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};

export default AdminBanners;
