import styled from "styled-components";
import { ListItemProps } from ".";

export const ListItem = styled.div<ListItemProps>`
  height: fit-content !important;

  @supports (display: grid) {
    width: 100%;
  }

  @supports not (display: grid) {
    width: 32.5%;

    @media (max-width: 619px) {
      width: 100%;
    }

    @media (min-width: 620px) and (max-width: 1023px) {
      width: 49%;
    }
  }

  display: flex;
  flex-direction: column;

  margin-bottom: 20px;
  transition: transform ${({ theme }) => theme.animation.duration};
  transform: translateY(0);
  will-change: transform;

  @media ${({ theme }) => theme.devices.laptop} {
    &:hover {
      ${({ hover }) =>
        hover && "transform: translateY(-10px)"}; /* Move para cima */
    }
  }
`;
