import React, { useState } from "react";
import * as Styled from "./styles";
import Select from "Components/Form/Inputs/Select";
import { white } from "Components/Form/Inputs/Select/themes";
const letters = [
  { name: "A-Z", value: "" }, // Adiciona a opção "A-Z" com valor vazio
  ...[
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z"
  ].map(letter => ({
    name: letter,
    value: letter
  }))
];

interface Props {
  setSelected: React.Dispatch<React.SetStateAction<string | null>>;
  selected: string | null;
}

export const FilterByLetter: React.FC<Props> = ({ selected, setSelected }) => {
  const handleSelect = (letter: string) => {
    setSelected(letter);
  };
  const renderSelect = () => (
    <Select
      change={value => handleSelect(value)}
      name="letters"
      options={letters}
      defaultValue={letters[0].value}
      theme={white}
      newSelect={true}
    />
  );

  return <Styled.Container>{renderSelect()}</Styled.Container>;
};

export default FilterByLetter;
