import api from "Helpers/Api";
import { IAd } from "Services/Ad/ad";

export interface ListProductsInGroupRequest {
  groupId: string;
}

export interface ListProductsInGroupResponse {
  id: string;
  product_group_id: string;
  product_id: string;
  created_at: string;
  disable_at: string | null;
  product: IAd;
  updated_at: string;
}

export default async function listProductsInGroup({
  groupId
}: ListProductsInGroupRequest) {
  if (!groupId) return undefined;
  const url = `productGroup/listProducts/${groupId}`;

  const { data } = await api.get<ListProductsInGroupResponse[]>(url);

  return data;
}
