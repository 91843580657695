import React, { ReactNode } from "react";

import * as Styled from "./styles";

interface Props {
  children?: ReactNode;
  link?: string;
  sideChildren?: ReactNode;
}

const PageTitle: React.FC<Props> = ({
  children = "",
  link = "",
  sideChildren = ""
}) => {
  return (
    <Styled.Container>
      <Styled.TitleContainer>
        {link !== "" ? (
          <Styled.TitleLink to={link}>{children}</Styled.TitleLink>
        ) : (
          <Styled.Title>{children}</Styled.Title>
        )}
        {sideChildren && sideChildren}
      </Styled.TitleContainer>
    </Styled.Container>
  );
};

export default PageTitle;
