import { useQuery } from "@tanstack/react-query";
import fetchStore from "Api/stores/fetch";

interface Props {
  slug: string;
}

export default function useStoreFetch({ slug }: Props) {
  const query = () => fetchStore({ slug });

  return useQuery({
    queryKey: ["storeFetch"],
    queryFn: query,
    keepPreviousData: true
  });
}
