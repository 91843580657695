import Search from "Components/Form/Inputs/Search";
import Loading from "Components/Loading";
import Pagination2 from "Components/Pagination2";
import useStoresList from "Hooks/Stores/useStoresList";
import React, { useCallback, useState } from "react";
import StoreItem from "./StoreItem";
import * as Styled from "./styles";

const AdminStores: React.FC = () => {
  const [value, setValue] = useState("");
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);

  const { data, isLoading } = useStoresList({
    page: page,
    perPage: 5,
    search: search,
    sort: "name",
    order: "asc"
  });

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const fetchList = useCallback(() => {
    setSearch(value);
  }, [value]);

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Title>Lojas</Styled.Title>
        <div style={{ width: 200, fontSize: "10em" }}>
          <Search
            setValue={setValue}
            placeholder="Pesquisar..."
            shadow={false}
            submit={() => fetchList()}
            newSearch={true}
          />
        </div>

        {isLoading ? (
          <Loading />
        ) : (
          <Styled.List>
            {data?.data.map(partner => (
              <StoreItem key={partner.id} data={partner} />
            ))}
            {data?.data && (
              <Pagination2
                lastPage={data.lastPage}
                onPageChange={handleChangePage}
                page={page}
                perPage={5}
              />
            )}
          </Styled.List>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};

export default AdminStores;
