import api from "Helpers/Api";
import { IPartners } from "Services/Stores";

interface Props {
  slug: string;
}

export default async function fetchStore({ slug }: Props) {
  const { data } = await api.get<IPartners>(`partners/fetch/${slug}`);

  return data;
}
