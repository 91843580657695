import Loading from "Components/Loading";
import Tooltips from "Components/Tooltips";
import { useLoading } from "Contexts/LoadingProvider";
import React, { Suspense } from "react";
import { Route, Switch } from "react-router";
import { AdminRoutes } from "Routes";
import AdminLogin from "./AdminLogin";
import ProtectedLayout from "./ProtectedLayout";

import AdminArticles from "./AdminArticles";
import AdminArticlesCreate from "./AdminArticles/Create";
import AdminArticlesEdit from "./AdminArticles/Edit";
import AdminBanners from "./AdminBanners";
import AdminBannersCreate from "./AdminBanners/Create";
import AdminBannersEdit from "./AdminBanners/Edit";
import AdminCampaigns from "./AdminCampaigns";
import AdminCampaignsCreate from "./AdminCampaigns/Create";
import AdminCampaignsEdit from "./AdminCampaigns/Edit";
import AdminFairs from "./AdminFairs";
import AdminFairsCreate from "./AdminFairs/Create";
import AdminFairsEdit from "./AdminFairs/Edit";
import AdminProducts from "./AdminProducts";
import AdminProdutHighlight from "./AdminProducts/Highlight";
import AdminStores from "./AdminStores";
import AdminStoresHighlight from "./AdminStores/Highlight";
import * as Styled from "./styles";

const AdminBase: React.FC = () => {
  const { isLoading } = useLoading();

  return (
    <>
      <Tooltips />
      {!isLoading ? (
        <Switch>
          <Route exact path={AdminRoutes.login}>
            <Suspense fallback={<Loading />}>
              <AdminLogin />
            </Suspense>
          </Route>
          <Route path={AdminRoutes.admin}>
            <ProtectedLayout>
              <Route exact path={AdminRoutes.fairs}>
                <Suspense fallback={<Loading />}>
                  <AdminFairs />
                </Suspense>
              </Route>
              <Route exact path={AdminRoutes.fairsEdit}>
                <Suspense fallback={<Loading />}>
                  <AdminFairsEdit />
                </Suspense>
              </Route>
              <Route exact path={AdminRoutes.fairsCreate}>
                <Suspense fallback={<Loading />}>
                  <AdminFairsCreate />
                </Suspense>
              </Route>
              <Route exact path={AdminRoutes.articles}>
                <Suspense fallback={<Loading />}>
                  <AdminArticles />
                </Suspense>
              </Route>
              <Route exact path={AdminRoutes.articleCreate}>
                <Suspense fallback={<Loading />}>
                  <AdminArticlesCreate />
                </Suspense>
              </Route>
              <Route exact path={AdminRoutes.articleEdit}>
                <Suspense fallback={<Loading />}>
                  <AdminArticlesEdit />
                </Suspense>
              </Route>
              <Route exact path={AdminRoutes.campaigns}>
                <Suspense fallback={<Loading />}>
                  <AdminCampaigns />
                </Suspense>
              </Route>
              <Route exact path={AdminRoutes.campaignsEdit}>
                <Suspense fallback={<Loading />}>
                  <AdminCampaignsEdit />
                </Suspense>
              </Route>
              <Route exact path={AdminRoutes.campaignsCreate}>
                <Suspense fallback={<Loading />}>
                  <AdminCampaignsCreate />
                </Suspense>
              </Route>
              <Route exact path={AdminRoutes.banners}>
                <Suspense fallback={<Loading />}>
                  <AdminBanners />
                </Suspense>
              </Route>
              <Route exact path={AdminRoutes.bannersEdit}>
                <Suspense fallback={<Loading />}>
                  <AdminBannersEdit />
                </Suspense>
              </Route>
              <Route exact path={AdminRoutes.bannersCreate}>
                <Suspense fallback={<Loading />}>
                  <AdminBannersCreate />
                </Suspense>
              </Route>
              <Route exact path={AdminRoutes.stores}>
                <Suspense fallback={<Loading />}>
                  <AdminStores />
                </Suspense>
              </Route>
              <Route exact path={AdminRoutes.storesHighlight}>
                <Suspense fallback={<Loading />}>
                  <AdminStoresHighlight />
                </Suspense>
              </Route>
              <Route exact path={AdminRoutes.products}>
                <Suspense fallback={<Loading />}>
                  <AdminProducts />
                </Suspense>
              </Route>
              <Route exact path={AdminRoutes.productsHighlight}>
                <Suspense fallback={<Loading />}>
                  <AdminProdutHighlight />
                </Suspense>
              </Route>
            </ProtectedLayout>
          </Route>
        </Switch>
      ) : (
        <Styled.LoadingContainer>
          <Loading />
        </Styled.LoadingContainer>
      )}
    </>
  );
};

export default AdminBase;
