import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Styled from "./styles";

import "filepond/dist/filepond.min.css";
import { FilePond, registerPlugin } from "react-filepond";

import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import localeActions from "Redux/Locale/actions";
import { ReducerState } from "Redux/store";

import ButtonPrimary from "Components/Buttons/Primary";
import Form from "Components/Form";
import TextInput from "Components/Form/Inputs/Text";
import validators from "Components/Form/Inputs/Text/Validations";
import api from "Helpers/Api";
import BackendErrorHelper from "Helpers/BackendErrorHelper";
import { convertPhone } from "Helpers/stringPhone";

import IconUserResearchNotCss from "Assets/Svg/user-research-not-css.jsx";
import CheckBox from "Components/Form/Inputs/CheckBox";
import defaultAxios from "axios";

import RadioSet from "Components/Form/Inputs/RadioSet";
import HoverInfoIcon from "Components/Info";
import LoadingIcon from "Components/LoadingIcon";
import { checkCpfCnpj } from "Helpers/checkCpfCnpj";
import { PERSON_TYPES } from "Helpers/consts";

interface Result {
  status: boolean;
  message: string;
}

interface Props {
  formDisabled?: boolean;
  formTitle?: string;
  formButtonText?: string;
  formRedirectLink?: string | null;
  crucialLeadCapture?: boolean;
  handleModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ILocation {
  name: string;
  value: number;
}

interface IUserStorage {
  name: string;
  email: string;
  phone: string;
  tries?: number;
}

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation);
registerPlugin(FilePondPluginFileValidateSize);

const FormStores: React.FC<Props> = ({
  formDisabled = false,
  formButtonText = "Enviar",
  formRedirectLink,
  crucialLeadCapture = false,
  handleModal
}) => {
  const token: string = useSelector((state: ReducerState) => state.auth.token);
  const user = useSelector((state: ReducerState) => state.auth.user);

  api.defaults.headers["Authorization"] = `bearer ${token}`;

  const dispatch = useDispatch();

  const [name, setName] = useState<string | null>("");
  const [storeName, setStoreName] = useState<string | null>("");
  const [email, setEmail] = useState<string | null>((user && user.email) || "");
  const [phone, setPhone] = useState<string | null>((user && user.phone) || "");
  const [isWhatsApp, setIsWhatsApp] = useState(false);
  // Not find types to use with filepond, if has any update on lib i will change this any type
  const [file, setFile] = useState<any>([]);
  const [website, setWebsite] = useState("");
  const [result, setResult] = useState<Result | null>(null);
  const [sent, setSent] = useState("");
  const [isSubmmiting, setIsSubmitting] = useState(false);
  const [useUser, setUseUser] = useState(false);
  const [radio, setRadio] = useState<string>(PERSON_TYPES.NATURAL);

  const [defaultError, setDefaultError] = useState<string[]>();

  type TInput = {
    name: string;
    email: string;
    phone: string;
    message: string;
    website: string;
    cpf: string;
    cnpj: string;
  };

  function showErrors(message: string) {
    setResult({
      status: false,
      message
    });
    dispatch(
      localeActions.addTooltip({
        type: "error",
        message
      })
    );
  }

  async function send({
    name,
    email,
    phone,
    website,
    message,
    cnpj,
    cpf
  }: TInput) {
    setIsSubmitting(true);
    if (!file.length) {
      showErrors("Insira um banner pra ser apresentado na sua loja.");
      setIsSubmitting(false);
      return;
    }

    const formdata = new FormData();

    if (useUser) {
      user && formdata.append("userId", user.id);
    }

    const validCnpjCpf = checkCpfCnpj({ cnpj, cpf });

    if (validCnpjCpf?.cnpj) {
      formdata.append("cnpj", validCnpjCpf.cnpj);
    }

    if (validCnpjCpf?.cpf) {
      formdata.append("cpf", validCnpjCpf.cpf);
    }

    name && formdata.append("name", name);
    storeName && formdata.append("storeName", storeName);
    email && formdata.append("email", email);
    message && formdata.append("description", message);
    phone && formdata.append("phone", convertPhone(phone));
    website && formdata.append("website", website.toLocaleLowerCase());
    // TODO: See why not accept boolean as value here, change on backend.
    isWhatsApp && formdata.append("whatsapp", "true");
    file.length > 0 && formdata.append("banner", file[0].file);

    const tooltipMessage = crucialLeadCapture
      ? "Boa sorte!"
      : "Contato enviado com sucesso";

    try {
      const response = await api.post(`/partners/create`, formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        }
      });

      setResult({ status: true, message: tooltipMessage });

      setSent(response.data.message);
    } catch (e) {
      if (defaultAxios.isAxiosError(e)) {
        if (BackendErrorHelper.checkIfDefaultError(e)) {
          const message = BackendErrorHelper.getDefaultErrorMessage(e.response);
          showErrors(message);
        } else if (BackendErrorHelper.checkIfValidationError(e)) {
          const message = BackendErrorHelper.getValidatorErrorMessage(
            e.response
          );
          showErrors(message);
        } else {
          showErrors("Erro na requisição, por favor tente novamente");
        }
      } else {
        showErrors("Verifique se todos os campos estão completos.");
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  const validateFields = () => {
    if (email && !validators["email"].validate(email)) {
      return false;
    }
    if (phone && !validators["phone"].validate(phone)) {
      return false;
    }
    return true;
  };

  return (
    <Styled.Container disable={formDisabled}>
      {sent ? (
        <>
          <Styled.SentMessageContainer>
            <Styled.SentMessageText>{sent}</Styled.SentMessageText>
            <Styled.ImageContainer>
              <IconUserResearchNotCss />
            </Styled.ImageContainer>
          </Styled.SentMessageContainer>
        </>
      ) : (
        <>
          <Styled.Title>Desejo fazer parte.</Styled.Title>
          <Styled.Result status={result ? result.status : true}>
            {result && result.message}
          </Styled.Result>
          <Form submit={send} defaultError={defaultError}>
            <>
              <Styled.FormRow>
                <TextInput
                  name="storeName"
                  label="Nome da loja*"
                  change={setStoreName}
                  placeholder="Digite o nome da loja aqui"
                  defaultValue={storeName}
                  required
                />
              </Styled.FormRow>
              <Styled.FormRow>
                <TextInput
                  name="name"
                  label="Responsável*"
                  change={setName}
                  placeholder="Digite o nome do responsável"
                  defaultValue={name}
                  required
                />
              </Styled.FormRow>
              <Styled.FormColumn>
                <TextInput
                  name="email"
                  label="Email*"
                  placeholder="Digite seu email"
                  change={setEmail}
                  required
                  defaultValue={email}
                  validation="email"
                />
                {user && (
                  <div className="user-container">
                    <CheckBox
                      name="user"
                      checked={useUser}
                      change={isChecked => setUseUser(isChecked)}
                    >
                      <Styled.InfoMessage>
                        <Styled.Terms>
                          Desejo vincular essa loja ao meu usuário
                        </Styled.Terms>
                        <HoverInfoIcon message="Vincularemos a loja atual ao seu email logado na plataforma." />
                      </Styled.InfoMessage>
                    </CheckBox>
                  </div>
                )}
              </Styled.FormColumn>
              <Styled.FormColumn>
                <RadioSet
                  name="radioCpfCnpj"
                  defaultValue={radio}
                  options={Object.values(PERSON_TYPES).map(type => ({
                    name: type,
                    value: type
                  }))}
                  change={value => {
                    setRadio(value);
                  }}
                />
                {radio === PERSON_TYPES.NATURAL ? (
                  <TextInput
                    name="cpf"
                    placeholder={"000.000.000-00"}
                    required
                    validation="cpfcnpj"
                    mask={"999.999.999-99"}
                  />
                ) : (
                  <TextInput
                    name="cnpj"
                    placeholder={"00.000.000/0000-00"}
                    required
                    validation="cpfcnpj"
                    mask={"99.999.999/9999-99"}
                  />
                )}
              </Styled.FormColumn>
              <Styled.FormColumn>
                <TextInput
                  name="phone"
                  label="Telefone*"
                  change={setPhone}
                  required
                  placeholder="Digite seu telefone"
                  defaultValue={phone}
                  mask={"(99) 99999-9999"}
                  validation="phone"
                />
                <div className="whatsapp-container">
                  <CheckBox
                    name="whatsapp"
                    checked={isWhatsApp}
                    change={isChecked => setIsWhatsApp(isChecked)}
                  >
                    <Styled.Terms>Este número é WhatsApp.</Styled.Terms>
                  </CheckBox>
                </div>
              </Styled.FormColumn>
              <Styled.FormRow>
                <TextInput
                  name="website"
                  label="URL da sua loja*"
                  change={setWebsite}
                  placeholder="Digite aqui o URL da sua loja"
                  defaultValue={website}
                  required
                  validation="url"
                />
              </Styled.FormRow>
              <Styled.FormRow>
                <FilePond
                  maxFileSize="5MB"
                  files={file}
                  allowMultiple={false}
                  onupdatefiles={setFile}
                  storeAsFile={true}
                  labelIdle={`
                  <div class="content-filepond">
                      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V3zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z"></path></svg>
                      <p>Clique ou arraste e solte seu banner aqui.</p>
                  </div>`}
                  credits={false}
                />
              </Styled.FormRow>
              <Styled.FormRow>
                <TextInput
                  name="message"
                  label="Descrição da sua loja*"
                  required
                  textarea
                  placeholder="Essa descrição irá aparecer na sua loja"
                />
              </Styled.FormRow>
              <Styled.FormRow>
                <CheckBox name="terms" required>
                  <Styled.Terms>
                    Autorizo a utilização dos meus dados de acordo com as{" "}
                    <a href="/informacoes/privacidade-confidencialidade">
                      Políticas de Privacidade
                    </a>{" "}
                    e aceito os{" "}
                    <a href="/informacoes/termo-lojas-online">
                      Termos e Condições
                    </a>
                  </Styled.Terms>
                </CheckBox>
              </Styled.FormRow>
              <Styled.SubmitContainer>
                <Styled.ButtonsContainer>
                  <ButtonPrimary type="submit" color="orange">
                    {isSubmmiting ? <LoadingIcon /> : <>{formButtonText}</>}
                  </ButtonPrimary>
                </Styled.ButtonsContainer>
              </Styled.SubmitContainer>
            </>
          </Form>
        </>
      )}
    </Styled.Container>
  );
};

export default FormStores;
