import styled from "styled-components";
import { FaSeedling } from "react-icons/fa";
import { Link } from "react-router-dom";

export const Container = styled.div`
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  padding-right: 20px;
  @media ${({ theme }) => theme.devices.tablet} {
    display: flex;
    min-width: 300px;
  }
`;

export const ArticleLink = styled(Link)`
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Content = styled.div`
  display: flex;
  text-decoration: none;
  flex-direction: column;
  border-radius: 0 0 5px 5px;
  margin-bottom: 0px;
  min-height: 120px;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  transition: transform ${({ theme }) => theme.animation.duration};
  transform: scale(1);
  will-change: transform;
  background-color: ${({ theme }) => theme.colors.white.normal};
  padding: 10px;
  flex-grow: 1;
  position:relative;
  box-shadow: ${({ theme }) => theme.shadow.vertical};
  border-radius: 5px;
  /* @media ${({ theme }) => theme.devices.laptop} {
    box-shadow: ${({ theme }) => theme.shadow.bottomRight};
    &:hover {
      transform: scale(1.02);
    }
  } */
  @media ${({ theme }) => theme.devices.tablet} {
    margin-bottom: 0px;
    width: 100%;
    min-height: 75px;
  }

  button {
    width: fit-content;
  }

  a {
    text-decoration: none;
  }

  
`;

export const Picture = styled.picture`
  min-width: 60px;
  min-height: 120px;
  max-height: 120px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  flex-grow: 1;
`;

export const Placeholder = styled(FaSeedling).attrs(props => ({
  icon: FaSeedling
}))`
  margin: 0 auto;
  font-size: 100px;
  padding: 15px;
  color: ${({ theme }) => theme.colors.gray.dark};
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
export const Info = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  letter-spacing: 1px;
  flex-grow: 1;
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.gray.normal};
`;

export const InfoContainer = styled.div`
  display: flex;

  flex-direction: column;
`;

export const Title = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: 500;
  text-transform: capitalize;
  min-height: 54px;
  padding-top: 15px;
  font-family: ${({ theme }) => theme.fonts.inter};
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.green.dark};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 5px;
  @media ${({ theme }) => theme.devices.tablet} {
    min-height: 22px;
  }
`;

export const Description = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: 400;
  text-transform: capitalize;
  font-family: ${({ theme }) => theme.fonts.inter};
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.gray.normal};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Details = styled.button`
  background-color: ${({ theme }) => theme.colors.green.normal};
  border: none;
  color: ${({ theme }) => theme.colors.white.normal};
  width: 100%;
  padding: 0;
  margin: 0;
  padding: 5px 0;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: ${({ theme }) => theme.fontSize.sm};
  cursor: pointer;
}
`;

export const Date = styled.span`
  background-color: ${({ theme }) => theme.colors.green.light};
  color: ${({ theme }) => theme.colors.white.normal};
  padding: 2px 5px;
  font-weight: 350;
  font-size: ${({ theme }) => theme.fontSize.sm};
  position: absolute;
  top: 0;
  right: 0;
`;

export const DeleteContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  align-items: center;
  justify-content: center;

  gap: 16px;

  p {
    font-weight: bold;
    font-size: 1.2rem;

    text-align: center;
  }
`;

export const DeleteContainerButtons = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  gap: 16px;

  buttons {
    width: fit-content;
  }
`;
