import styled from "styled-components";

import { FaWhatsapp } from "react-icons/fa";

interface IContainer {
  disable: boolean;
}
export const Container = styled.div<IContainer>`
  background-color: ${({ theme }) => theme.colors.white.normal};
  border-radius: 5px;
  margin-top: -100px;
  padding: 0 16px 16px 16px;
  opacity: ${({ disable }) => (disable ? 0.2 : 1)};
  pointer-events: ${({ disable }) => (disable ? "none" : "auto")};

  width: 650px;

  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);

  margin-bottom: 16px;

  @media ${({ theme }) => theme.devices.mobileL} {
    width: 100%;
    max-width: 80vw;
  }
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.green.dark};
  letter-spacing: 1px;
  font-size: ${({ theme }) => theme.fontSize.md2};
  font-weight: 600;
  padding: 16px 0;
  margin: 0;
`;

export const InviteDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InviteText = styled.h1`
  margin: 14px 0 4px 0;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.gray.normal};
  letter-spacing: 1px;
  font-size: ${({ theme }) => theme.fontSize.mm};
  text-align: center;
`;

export const InviteSubText = styled.p`
  margin: 12px 0;
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.gray.dark};
  letter-spacing: 1px;
  font-size: ${({ theme }) => theme.fontSize.mm};
  text-align: center;
`;

export const Form = styled.form``;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;

  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }

  .filepond--wrapper {
    width: 100%;
  }

  .filepond--panel-root {
    background: #fff;

    border: 1px dashed #a0aec0;
  }

  .filepond--drop-label label {
    width: 100%;
  }
  .content-filepond {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
  }

  .content-filepond p {
    margin: 0 auto;
  }
  .content-filepond svg {
    margin-left: 32px;
    font-size: var(--chakra-fontSizes-4xl);
    color: var(--chakra-colors-green-700);
  }
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;

  .whatsapp-container {
    align-items: center;
    margin-bottom: 20px;
  }

  .user-container {
    display: flex;
    width: 100%;
    margin-top: -11px;
    align-items: end;
    margin-bottom: 20px;
  }
`;

export const Select = styled.div`
  flex-grow: 1;
  margin-top: 5px;
  margin-left: 10px;
  margin-bottom: 20px;

  &:first-child {
    margin-right: 10px;
    margin-left: 0;
  }

  & > div {
    display: flex;
    margin: 0;

    input {
      flex-grow: 1;
    }
  }
  @media ${({ theme }) => theme.devices.tablet} {
    margin-left: 0;
    &:first-child {
      margin-top: 10px;
      margin-right: 0;
    }
  }
`;
export const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  margin-top: 16px;
`;

interface IResult {
  status: boolean;
}

export const Result = styled.span<IResult>`
  font-size: ${({ theme }) => theme.fontSize.sm};
  display: table;
  margin: 0 auto;
  height: 12px;
  padding: 10px 0;
  color: ${({ theme, status }) =>
    status ? theme.colors.green.normal : theme.colors.red.normal};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin-left: auto;

    svg {
      margin-right: 8px;
      margin-top: 1px;
    }
  }

  a {
    margin-left: auto;
    opacity: 1;
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const WhatsappIcon = styled(FaWhatsapp).attrs(props => ({
  icon: FaWhatsapp
}))`
  align-self: flex-end;
  color: ${({ theme }) => theme.colors.white.normal};
  background-color: #25d366;
  height: 38px;
  padding: 0 10px;
  font-size: 28px;
  border-radius: 3px;
`;

export const SentMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const SentMessageText = styled.p`
  margin: 0;
  padding: 0;

  margin-top: 16px;

  font-weight: bold;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.md2};
  color: ${({ theme }) => theme.colors.green.normal};
`;

export const ImageContainer = styled.div`
  width: 100%;
  max-width: 350px;
`;

export const Terms = styled.p`
  display: inline-block;

  margin: 0;
  padding: 0;
  font-size: ${({ theme }) => theme.fontSize.sm};

  a {
    color: #007185;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const InfoMessage = styled.div`
  display: flex;

  gap: 6px;
`;
