import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div<{ backgroundImage?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 36px;
  padding-bottom: 36px;
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage})` : "none"};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 90vw;
  max-width: ${({ theme }) => theme.siteWidth};
  justify-content: flex-start;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 48px;
  font-family: ${({ theme }) => theme.fonts.inter};
  color: #1a1a1a;

  @media ${({ theme }) => theme.devices.tablet} {
    font-size: ${({ theme }) => theme.fontSize.md2};
  }
`;

export const TitleLink = styled(Link)`
  padding-right: 20px;
  font-weight: 600;
  font-size: 48px;
  font-family: ${({ theme }) => theme.fonts.inter};
  color: #1a1a1a;
  text-decoration: none;

  @media ${({ theme }) => theme.devices.tablet} {
    font-size: ${({ theme }) => theme.fontSize.md2};
  }
`;
