import React from "react";
import { HiOutlineChartBar } from "react-icons/hi";
import Routes from "Routes";
import { IMenuItemModel } from "../index";

const menu: IMenuItemModel = {
  name: "AgroBoletins",
  icon: <HiOutlineChartBar />,
  link: Routes.powerbi
};

export default menu;
