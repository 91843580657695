import React from "react";
import styled from "styled-components";

const Card = styled.a`
  display: flex;
  align-items: center;
  background-color: #fafafa;
  border: 1px solid #dadada;
  border-radius: 8px;
  padding: 16px;
  max-height: 170px;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px); /* Eleva o botão levemente */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra */
  }
`;

const ImageContainer = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 16px;
  background-color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Content = styled.div`
  flex: 1;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-weight: 700;
  color: #0f0f0f;
`;

const Text = styled.p`
  margin: 4px 0;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: 12px;
  color: #0f0f0f;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limita o texto a 2 linhas */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis; /* Adiciona "..." ao final */
`;

const Button = styled.a`
  display: inline-block;
  margin-top: 8px;
  padding: 8px;
  background-color: #28a745;
  color: #ffffff;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border-radius: 6px;
  text-decoration: none;

  &:hover {
    background-color: #218838;
  }
`;

interface Props {
  banner?: string;
  name: string;
  slug: string;
  description: string;
}

const StoreItem: React.FC<Props> = ({ banner, name, slug, description }) => {
  return (
    <Card href={`/stores/${slug}`}>
      <ImageContainer style={{ border: "1px solid lightgray" }}>
        <img
          src={
            banner ||
            "https://storage.googleapis.com/rurax/docs/rx-lojaoficial.jpg"
          }
          alt={"Logo"}
        />
      </ImageContainer>
      <Content>
        <Title
          style={{
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical"
          }}
        >
          {name}
        </Title>
        <Text>{description}</Text>
        <Button href={`/stores/${slug}`}>Visitar loja</Button>
      </Content>
    </Card>
  );
};

export default StoreItem;
