import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  max-width: 345px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 24px;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white.normal};
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  flex-direction: column;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
  flex-direction: column;
  @media ${({ theme }) => theme.devices.laptop} {
    margin-right: 20px;
  }
  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
    margin-top: 40px;
  }
`;

interface TitleFilterProps {
  searchHidden: boolean;
}
export const Title = styled.span<TitleFilterProps>`
  display: flex;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.green.dark};
  margin-bottom: 8px;
  margin-top: ${({ searchHidden }) => (searchHidden ? "16px" : "0")};
`;

export const Expand = styled.div`
  margin-left: auto;
  font-size: 12px;
  align-self: center;
`;

interface IContent {
  expand: boolean;
}
export const Content = styled.div<IContent>`
  overflow: hidden;

  @media ${({ theme }) => theme.devices.tablet} {
    transition: max-height ${({ theme }) => theme.animation.duration};
    max-height: ${({ expand }) => (expand ? "100vh" : "1px")};
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0;

  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;

export const TotalResults = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.gray.dark};
  white-space: nowrap;
  margin-left: auto;
  margin-top: 20px;
  @media ${({ theme }) => theme.devices.tablet} {
    margin-top: 20px;
    margin-left: 0;
    text-align: right;
  }
`;
