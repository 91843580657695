import Loading from "Components/Loading";
import React from "react";
import LazyLoad from "react-lazyload";
import LogoCeia from "../../../../Assets/Images/Partners/CEIA.png";
import LogoEmbrapii from "../../../../Assets/Images/Partners/Embrapii.png";
import LogoSebrae from "../../../../Assets/Images/Partners/Sebrae.png";
import LogoUFG from "../../../../Assets/Images/Partners/UFG.png";
import * as Styled from "./styles";

const Partners: React.FC = () => {
  return (
    <Styled.PartnersContainers>
      <Styled.PartnersTitle>Somos acelerados por:</Styled.PartnersTitle>
      <Styled.PartnersContent>
        <a
          href="https://www.inf.ufg.br/p/39527-centro-de-excelencia-em-inteligencia-artificial-ceia"
          target="_blank"
          rel="extern noreferrer"
        >
          <LazyLoad
            placeholder={<Loading />}
            height={200}
            offset={[-200, 0]}
            once
          >
            <Styled.CEIALogo width="150" src={LogoCeia} alt="CEIA logo" />
          </LazyLoad>
        </a>
        <a href="https://www.ufg.br/" target="_blank">
          <LazyLoad placeholder={<Loading />} height={200} offset={100} once>
            <Styled.UFGLogo height="107" src={LogoUFG} alt="UFG logo" />
          </LazyLoad>
        </a>
      </Styled.PartnersContent>
      <Styled.PartnersContent>
        <a href="https://embrapii.org.br/" target="_blank">
          <LazyLoad placeholder={<Loading />} height={200} offset={100} once>
            <Styled.EmbrapiiLogo
              width="98"
              src={LogoEmbrapii}
              alt="Embrapii logo"
            />
          </LazyLoad>
        </a>
        <a href="https://www.sebrae.com.br/sites/PortalSebrae/" target="_blank">
          <LazyLoad placeholder={<Loading />} height={200} offset={100} once>
            <Styled.SebraeLogo width="127" src={LogoSebrae} alt="SEBRAE logo" />
          </LazyLoad>
        </a>
      </Styled.PartnersContent>
    </Styled.PartnersContainers>
  );
};

export default Partners;
