import React, {
  createRef,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import FormContext from "Components/Form/context";

import * as Styled from "./styles";
import HiddenField from "../Hidden";

interface Props {
  name?: string;
  required?: boolean;
  setValue(text: string): void;
  submit?(): void;
  placeholder?: string;
  border?: string;
  shadow?: boolean;
  defaultValue?: string;
  transparent?: boolean;
  newSearch?: boolean;
}

const Search: React.FC<Props> = ({
  setValue,
  submit,
  placeholder = "",
  border = "5px",
  shadow = true,
  defaultValue = "",
  required = false,
  name = "Search",
  transparent = true,
  newSearch = false
}) => {
  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
    setSearch(e.target.value);
  }
  const { registerField, deleteField } = useContext(FormContext);
  const inputRef = useMemo(() => createRef<HTMLInputElement>(), []);
  const [search, setSearch] = useState("");

  useEffect(() => {
    registerField &&
      inputRef.current &&
      name &&
      registerField(name, inputRef.current, false, false);
    return () => {
      deleteField && deleteField(name);
    };
  }, [inputRef, name, registerField, deleteField, required]);

  return (
    <Styled.Container newWidth={newSearch} border={border} shadow={shadow}>
      <Styled.Input
        onChange={onChange}
        onKeyUp={e => {
          if (e.key === "Enter") {
            submit && submit();
          }
        }}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
      <HiddenField name={name} value={search} />

      <Styled.SearchButton
        transparent={transparent}
        onClick={() => submit && submit()}
      >
        <Styled.SearchIcon newWidth={newSearch} transparent={transparent} />
      </Styled.SearchButton>
    </Styled.Container>
  );
};

export default Search;
