import styled from "styled-components";
import { Link } from "react-router-dom";
import colors from "color";

interface ILink {
  color: "green" | "red" | "gray" | "orange";
}

export const LinkButton = styled(Link)<ILink>`
  align-self: end;
  text-align: center;

  color: white;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-weight: 600;
  text-decoration: none;

  min-width: 293px;
  padding: 10px 20px;
  margin: 20px 0;

  border-radius: ${({ theme }) => theme.borderRadius};
  color: ${({ theme }) => theme.colors.white.normal};
  background-color: ${({ theme, color }) => theme.colors[color].normal};
  transition: transform ${({ theme }) => theme.animation.duration};
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }

  &:active {
    background-color: ${({ theme, color }) =>
      colors(theme.colors[color].normal)
        .darken(0.2)
        .toString()};
  }

  @media ${({ theme }) => theme.devices.tablet} {
    align-self: center;
    font-size: ${({ theme }) => theme.fontSize.mm};
    padding: 10px 0;
    width: 100%;
  }
`;
