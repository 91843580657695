import api from "Helpers/Api";
import { IBannerRequest, TBannerResponse } from "Services/Banners/banner";

export default async function bannerUpdate(
  bannerId: string,
  params: IBannerRequest
) {
  const { data } = await api.put<TBannerResponse>(
    `/banners/${bannerId}`,
    params
  );
  return data;
}
