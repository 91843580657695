import styled from "styled-components";

export const Container = styled.div``;
export const BackgroundDiv = styled.div`
  width: 100%;
  padding-top: 36px;
  padding-bottom: 36px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const TitleContainer = styled.div`
  width: 90vw;
  max-width: ${({ theme }) => theme.siteWidth};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;

    img {
      display: block;
    }

    h1 {
      font-size: 1.6rem;
      margin: 0;
    }

    button {
      width: fit-content;
      height: fit-content;
      font-size: 1rem;
    }
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;

  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;

export const TotalResults = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.gray.dark};
  white-space: nowrap;
  margin-left: 100px;
  margin-top: auto;
  @media ${({ theme }) => theme.devices.tablet} {
    margin-top: 20px;
    margin-left: 0;
    text-align: right;
  }
`;

export const Filters = styled.div`
  min-width: 30%;
  width: 30%;

  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
  }
`;

export const Filter = styled.div`
  margin-bottom: 20px;
`;
export const ListContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const List = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: flex-start;
  margin-left: 20px;

  @media ${({ theme }) => theme.devices.tablet} {
    margin-left: 0;
  }
`;

export const NoResults = styled.span`
  margin-top: 20px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.gray.dark};
  letter-spacing: 1px;
  flex-grow: 1;
`;

export const EmptyItem = styled.div`
  width: 280px;
  height: 130px;
  margin-bottom: 20px;
`;
