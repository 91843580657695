import React, { ReactNode, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { ReducerState } from "Redux/store";

import { FaPlus, FaMinus } from "react-icons/fa";

import * as Styled from "./styles";
import Search from "Components/Form/Inputs/Search";

interface Props {
  children?: ReactNode;
  searchHidden?: boolean;
  total?: number;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
}

const Filters: React.FC<Props> = ({
  children = "",
  searchHidden = true,
  total = 0,
  setSearch
}) => {
  const { mobile } = useSelector((state: ReducerState) => state.locale);
  const [expand, setExpand] = useState(false);

  const [value, setValue] = useState("");

  const fetchList = useCallback(() => {
    if (setSearch) {
      setSearch(value);
    }
  }, [setSearch, value]);

  return (
    <Styled.PageContainer>
      <Styled.Container style={{ marginTop: 20 }}>
        {!searchHidden && (
          <Styled.SearchContainer>
            <Styled.Title
              style={{ color: "#1A1A1A" }}
              searchHidden={searchHidden}
              className="filters-title"
              onClick={() => setExpand(!expand)}
            >
              Pesquisar
            </Styled.Title>
            <Search
              setValue={setValue}
              placeholder="Pesquisar. ."
              defaultValue={value}
              shadow={false}
              submit={() => fetchList()}
              newSearch={true}
            />
          </Styled.SearchContainer>
        )}
      </Styled.Container>
      {children}
    </Styled.PageContainer>
  );
};

export default Filters;
