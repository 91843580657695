import api from "Helpers/Api";
import { IPartners, PartnersRequestList } from "Services/Stores";
import { IPagination } from "Services/pagination";

export default async function storeList({
  search,
  selectedLetter,
  page,
  perPage,
  sort,
  order,
  categories,
  ...rest
}: PartnersRequestList) {
  const arr = categories?.split(",").map(c => c.trim()) ?? [];
  if (arr.length > 0 && arr.every(item => item.includes("vazio"))) {
    return {
      data: [],
      total: "0",
      sort: "",
      order: "asc"
    } as any;
  } else {
    categories = arr.filter(item => !item.includes("vazio")).join(",");
  }

  if (selectedLetter) {
    const { data } = await api.get<IPagination<IPartners>>(`partners/list`, {
      params: {
        search: selectedLetter,
        firstLetter: selectedLetter,
        perPage,
        page,
        sort,
        order,
        categories,
        ...rest
      }
    });
    return data;
  }

  const { data } = await api.get<IPagination<IPartners>>(`partners/list`, {
    params: {
      search,
      perPage,
      page,
      sort,
      order,
      categories,
      ...rest
    }
  });

  return data;
}
