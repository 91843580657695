import api from "Helpers/Api";

export default async function storeHighlight(
  partnerId: string,
  formData: FormData
) {
  const { data } = await api.put<void>(
    `/partners/highlight/${partnerId}`,
    formData
  );
  return data;
}
