import { useQuery } from "@tanstack/react-query";
import bannersList from "Api/banners/list";
import { TBannerResponse } from "Services/Banners/banner";
import { IPagination } from "Services/pagination";

export default function useBanners() {
  async function query(): Promise<IPagination<TBannerResponse>> {
    return await bannersList();
  }

  return useQuery({
    queryKey: ["bannersList"],
    queryFn: query,
    keepPreviousData: true
  });
}
