import styled from "styled-components";

export const Container = styled.div``;

interface TitleFilterProps {
  searchHidden: boolean;
}
export const Title = styled.span<TitleFilterProps>`
  display: flex;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: 600;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.green.dark};
  margin-bottom: 20px;
  margin-top: ${({ searchHidden }) => (searchHidden ? "16px" : "0")};
  padding: 0 16px;
`;

export const Expand = styled.div`
  margin-left: auto;
  font-size: 12px;
  align-self: center;
`;

interface IContent {
  expand: boolean;
}
export const Content = styled.div<IContent>`
  overflow: hidden;

  @media ${({ theme }) => theme.devices.tablet} {
    transition: max-height ${({ theme }) => theme.animation.duration};
    max-height: ${({ expand }) => (expand ? "100vh" : "1px")};
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
  margin: 20px 0 0 0;

  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;

export const TotalResults = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.gray.dark};
  white-space: nowrap;
  margin-left: auto;
  margin-top: 20px;
  @media ${({ theme }) => theme.devices.tablet} {
    margin-top: 20px;
    margin-left: 0;
    text-align: right;
  }
`;
