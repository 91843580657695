import ButtonPrimary from "Components/Buttons/Primary";
import "moment/locale/pt-br";
import React from "react";
import { FaCheckCircle, FaEdit } from "react-icons/fa";
import { useHistory } from "react-router";
import { IPartners } from "Services/Stores";
import * as Styled from "./styles";

interface Props {
  data: IPartners;
}

const StoreItem: React.FC<Props> = ({ data: partner }) => {
  const history = useHistory();

  const { highlight_start_at, highlight_expires_at } = partner;
  let isHighlighted = false;
  if (highlight_start_at && highlight_expires_at) {
    const start = new Date(highlight_start_at);
    const end = new Date(highlight_expires_at);
    const now = new Date();
    if (now.getTime() >= start.getTime() && now.getTime() <= end.getTime()) {
      isHighlighted = true;
    }
  }

  return (
    <Styled.Container>
      <Styled.Content>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ padding: "10px" }}>
            <Styled.Store>{partner.name}</Styled.Store>
          </div>
          {isHighlighted && (
            <div style={{ marginRight: "10px" }}>
              <FaCheckCircle />
            </div>
          )}
          <div>
            <ButtonPrimary
              color="green"
              action={() =>
                history.push(`/admin/stores/highlight/${partner.slug}`)
              }
            >
              <FaEdit /> Configurar Destaque
            </ButtonPrimary>
          </div>
        </div>
      </Styled.Content>
    </Styled.Container>
  );
};

export default StoreItem;
