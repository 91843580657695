import * as Styled from "./styles";
import React from "react";
import Select from "Components/Form/Inputs/Select";
import { white } from "Components/Form/Inputs/Select/themes";
import { GENERAL_FILTER, GENERAL_SORTINGS } from "Helpers/consts";
import { TOrder } from "Services/pagination";

export type TSortingValue = {
  sort?: string;
  order?: TOrder;
};

export type TSortingOptions = {
  name: string;
  value: TSortingValue;
};

interface Props {
  sortingOptions?: TSortingOptions[];
  setSorting: React.Dispatch<React.SetStateAction<any>>;
  newSelect?: boolean;
  filter?: boolean;
}

const Sorting: React.FC<Props> = ({
  setSorting,
  sortingOptions,
  newSelect = false,
  filter = false
}) => {
  const options = filter ? GENERAL_FILTER : GENERAL_SORTINGS;
  const renderSelect = () => (
    <Select
      change={value => setSorting(value)}
      name="sorting"
      options={options}
      defaultValue={options[0]?.value}
      theme={white}
      newSelect={newSelect}
    />
  );

  if (newSelect) {
    return <Styled.SortContainerNew>{renderSelect()}</Styled.SortContainerNew>;
  } else {
    return <Styled.SortContainer>{renderSelect()}</Styled.SortContainer>;
  }
};

export default Sorting;
