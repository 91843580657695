import api from "Helpers/Api";

export default async function productHighlight(
  productId: string,
  formData: FormData
) {
  const { data } = await api.put<void>(
    `/products/highlight/${productId}`,
    formData
  );
  return data;
}
