import React, { useState } from "react";
import * as Styled from "./styles";
import DatePicker from "react-date-picker";
import { INITIAL_CHART_DATE } from "Helpers/consts";
import styled from "styled-components";

export interface IRangeDate {
  startDate: Date;
  endDate: Date;
}

interface Props {
  title?: string;
  name: string;
  initialStartDate?: Date;
  initialEndDate?: Date;
  changeStartDate(e: Date | null): void;
  changeEndDate(e: Date | null): void;
  maxDate?: Date;
  minDate?: Date;
  labelStartDate?: string;
  labelEndDate?: string;
}

const StyledDatePicker = styled(DatePicker)`
  input {
    font-family: "Inter", sans-serif;
  }
`;

const RangeDateField: React.FC<Props> = ({
  title = "",
  name,
  initialStartDate = null,
  initialEndDate = null,
  changeStartDate,
  changeEndDate,
  maxDate = new Date(),
  minDate = new Date(INITIAL_CHART_DATE),
  labelStartDate = "De",
  labelEndDate = "Até"
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  return (
    <Styled.Field>
      {title && <Styled.Title>{title}</Styled.Title>}
      <Styled.Labels>
        <Styled.Label>
          <Styled.DateTile>{labelStartDate}</Styled.DateTile>
          <StyledDatePicker
            onChange={(date: Date | null) => {
              if (date === null && startDate === null) return;
              setStartDate(date);
              changeStartDate(date);
            }}
            value={startDate}
            name="start_date"
            // maxDate={maxDate}
            // minDate={minDate}
            format="dd/MM/yyyy"
            dayPlaceholder="--"
          />
        </Styled.Label>
        <Styled.Label>
          <Styled.DateTile>{labelEndDate}</Styled.DateTile>
          <StyledDatePicker
            onChange={(date: Date | null) => {
              if (date === null && endDate === null) return;
              setEndDate(date);
              changeEndDate(date);
            }}
            value={endDate}
            name="end_date"
            // maxDate={maxDate}
            // minDate={minDate}
            format="dd/MM/yyyy"
            dayPlaceholder="--"
          />
        </Styled.Label>
      </Styled.Labels>
    </Styled.Field>
  );
};

export default RangeDateField;
