import { ReducerState } from "Redux/store";
import React, { ReactNode, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import articles from "./MenuItems/Articles";
import categories from "./MenuItems/Categories";
import jobs from "./MenuItems/Jobs";
import leases from "./MenuItems/Leases";
import products from "./MenuItems/Products";
import productsHilight from "./MenuItems/ProductsHighlight";
import properties from "./MenuItems/Properties";
import vehicles from "./MenuItems/Vehicles";
import * as Styled from "./styles";
// import services from "./MenuItems/Services";
import boletins from "./MenuItems/Boletins";
import EarnMoney from "./MenuItems/EarnMoney";
import fairs from "./MenuItems/Fairs";
import Links from "./MenuItems/Links";
import NeedHelp from "./MenuItems/NeedHelp";
import powerbi from "./MenuItems/PowerBi";
import quotations from "./MenuItems/Quotations";
import RuraxInfo from "./MenuItems/RuraxInfo";
import stores from "./MenuItems/Stores";

import ButtonPrimary from "Components/Buttons/Primary";
import localeActions from "Redux/Locale/actions";
import Routes from "Routes";
import { MARKETPLACE } from "config";
import Profile from "../Profile";
import SiteMap from "./MenuItems/SiteMap";

export interface ISubmenuModel {
  category?: string;
  link?: string;
  items?: IMenuItemModel[];
}
export interface IMenuItemModel {
  name: string;
  icon?: ReactNode;
  link: string;
  submenu?: ISubmenuModel[];
}

export const MenuItems: IMenuItemModel[] = [
  properties,
  leases,
  products,
  vehicles,
  jobs,
  // services,
  quotations,
  fairs,
  stores,
  Links
];

export const FooterMenuItems: IMenuItemModel[] = [
  EarnMoney,
  RuraxInfo,
  NeedHelp,
  SiteMap
];

interface Props {
  account(value: boolean): void;
}

const Menu: React.FC<Props> = ({ account }) => {
  const mobile = useSelector((state: ReducerState) => state.locale.mobile);
  const [toggle, setToggle] = useState(true);
  const [expand, setExpand] = useState<number>(-1);
  const [category, setCategory] = useState<number>();
  const [hover, setHover] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerState) => state.auth);
  const history = useHistory();

  const menu: IMenuItemModel[] = [
    // properties,
    // leases,
    products,
    productsHilight,
    stores,
    articles,
    // vehicles,
    // jobs,
    // services,
    quotations,
    fairs,
    powerbi,
    categories
  ];

  // Fix to close the menu when the link is clicked

  const linkClick = () => {
    setToggle(!toggle);
    setHover(false);
    setExpand(-1);
    setCategory(-1);
    setTimeout(() => setHover(true), 200);
    //document.body.style.overflow = "unset"; estava gerando scroll horizontal desnecessário
  };

  const mobileToggle = () => {
    setToggle(!toggle);
    toggle
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  };

  const handleShow = () => {
    user ? dispatch(localeActions.modal()) : history.push(Routes.login);
  };

  const handleAdPlans = () => {
    user ? history.push(Routes.adplans) : history.push(Routes.login);
  };

  const handleGoToAnnounceStore = () => {
    return history.push(Routes.storeAnnoucement);
  };

  const handleSetOpenSubMenu = (key: number) => {
    setHover(true);
    setExpand(key);
  };

  const handleSetCloseSubMenu = () => {
    setHover(false);
    setExpand(-1);
  };

  return (
    <Styled.HeaderContainer>
      {mobile && (
        <Styled.MobileToggle id="mobile-menu-open" onClick={mobileToggle} />
      )}
      <Styled.MenuContainer id="menu-container" toggle={toggle}>
        {mobile && <Profile account={account} toggleMenu={mobileToggle} />}
        {menu.map((item, key) => (
          <Styled.MenuItem
            className={item.submenu && "menu-with-submenu"}
            key={`menu-item-${key}`}
            onMouseEnter={() => !mobile && handleSetOpenSubMenu(key)}
            onMouseLeave={() => !mobile && handleSetCloseSubMenu()}
          >
            <Styled.MenuItemLink
              className="item-link"
              to={item.link || ""}
              activeClassName={item.link ? "active" : ""}
              onClick={mobile ? linkClick : () => {}}
            >
              <>
                {item.icon && <>{item.icon}</>}
                {item.name}
              </>
            </Styled.MenuItemLink>
            {mobile && item.submenu && (
              <Styled.ExpandContainer
                className="expand-submenu"
                color="#FFF"
                onClick={() => setExpand(expand === key ? -1 : key)}
              >
                {expand === key ? (
                  <Styled.ExpandLess className="icon-less" />
                ) : (
                  <Styled.ExpandMore className="icon-more" />
                )}
              </Styled.ExpandContainer>
            )}

            {item.submenu && (
              <Styled.SubmenuContainer
                className="submenu-container"
                expand={expand === key}
                hover={hover && expand === key}
              >
                {item.submenu.map((submenu, subKey) => (
                  <Styled.SubmenuCategory key={`submenu-${subKey}`}>
                    {submenu.category && (
                      <>
                        <Styled.SubmenuCategoryTitle
                          to={submenu.link || ""}
                          activeClassName="active"
                          onClick={linkClick}
                        >
                          {submenu.category}
                        </Styled.SubmenuCategoryTitle>
                        {/* 
                        TODO: Verify if will have some subcategories inside subcategories
                        {mobile && submenu.items && expand === key && (
                          <Styled.ExpandContainer
                            className="expand-sub-submenu"
                            color={theme.colors.white.normal}
                          >
                            {category === subKey ? (
                              <Styled.ExpandLess className="submenu-icon-less" />
                            ) : (
                              <Styled.ExpandMore className="submenu-icon-more" />
                            )} 
                          </Styled.ExpandContainer> 
                        )}*/}
                      </>
                    )}
                  </Styled.SubmenuCategory>
                ))}
              </Styled.SubmenuContainer>
            )}
          </Styled.MenuItem>
        ))}
        {!mobile && MARKETPLACE && (
          <Styled.Buttonn onClick={handleAdPlans}>Destaques</Styled.Buttonn>
        )}
        {!mobile && MARKETPLACE && <Styled.DividerVertical />}
        {!mobile && (
          <Styled.Buttonn onClick={handleShow}>Anuncie grátis</Styled.Buttonn>
        )}
      </Styled.MenuContainer>
      {mobile && !toggle && (
        <Styled.MenuClose id="mobile-menu-close" onClick={mobileToggle} />
      )}
    </Styled.HeaderContainer>
  );
};

export default Menu;
