import colors from "color";
import styled from "styled-components";

interface IButton {
  disable: boolean;
  color: "green" | "red" | "gray" | "orange";
  borderRadius?: string;
}

export const Button = styled.button<IButton>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 150px;
  height: 41px;
  padding: 10px 20px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: ${({ theme }) => theme.fontSizePx.sm};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white.normal};
  border: none;
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ theme, disable, color }) =>
    disable ? theme.colors.gray.normal : theme.colors[color].normal};
  cursor: ${({ disable }) => (disable ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${({ theme, disable, color }) =>
      disable
        ? theme.colors.gray.normal
        : colors(theme.colors[color].normal)
            .lighten(0.2)
            .toString()};
  }

  &:active {
    background-color: ${({ theme, disable, color }) =>
      disable
        ? theme.colors.gray.normal
        : colors(theme.colors[color].normal)
            .darken(0.2)
            .toString()};
  }

  &:focus {
    outline: none;
  }
`;
