import DinProBlack from "Assets/Fonts/DinPro/DINPro-Black.ttf";
import DinProBold from "Assets/Fonts/DinPro/DINPro-Bold.ttf";
import DinProLight from "Assets/Fonts/DinPro/DINPro-Light.ttf";
import DinProMedium from "Assets/Fonts/DinPro/DINPro-Medium.ttf";
import DinProRegular from "Assets/Fonts/DinPro/DINPro-Regular.ttf";
import { default as Color, default as color } from "color";
import { createGlobalStyle } from "styled-components";

export const FontsImport = createGlobalStyle`
  @font-face {
    font-family: 'dinpro';
      src: url(${DinProBlack});
    font-weight: bolder;
  }
  @font-face {
    font-family: 'dinpro';
      src: url(${DinProBold});
    font-weight: bold;
  }
  @font-face {
    font-family: 'dinpro';
      src: url(${DinProLight});
    font-weight: lighter;
  }
  @font-face {
    font-family: 'dinpro';
      src: url(${DinProMedium});
    font-weight: light;
  }
  @font-face {
    font-family: 'dinpro';
      src: url(${DinProRegular});
    font-weight: normal;
  }
`;

export const theme = {
  colors: {
    white: {
      light: "#FFFFFF",
      normal: "#FFFFFF",
      dark: "#FFFFFF"
    },
    black: {
      normal: "#000000",
      gradiente01Footer: "#09080300",
      gradiente02Footer: "#070A18"
    },
    newBlack: {
      black1: "#0000007D"
    },
    gray: {
      super_light: "#FCFCFC",
      light: "#EBEBEB",
      medium: "#D9D9D9",
      normal: "#939598",
      dark: "#58595b"
    },
    grayNew: {
      gray1: "#CCCCCC",
      gray2: "#E6E6E6",
      gray3: "#1F1F1F",
      gray4: "#C5C6CC",
      gray5: "#595959",
      gray6: "#0F0F0F",
      gray7: "#DADADA",
      gray8: "#FAFAFA"
    },
    green: {
      100: "#C6F6D5",
      200: "rgba(131, 239, 155, 0.5)",
      300: "#68D391",
      400: "#48BB78",
      700: "#53B167",
      800: "#22543D",
      darker: "#0E4D03",
      dark: "#02720C",
      normal: "#4cb967",
      light: "#22CC4F"
    },
    newGreen: {
      green1: "#50B1651A",
      green2: "#67B02E1A",
      green3: "#45B062"
    },
    red: {
      light: color("#E14C3A")
        .lighten(0.2)
        .toString(),
      normal: "#E14C3A",
      dark: "#D22727"
    },
    newRed: {
      red1: "#DC2626"
    },
    orange: {
      light: color("rgb(234, 125, 36)")
        .lighten(0.2)
        .toString(),
      normal: "rgb(234, 125, 36)"
    }
  },
  fonts: {
    primary: "Poppins, sans-serif;",
    secundary: "Poppins, sans-serif;",
    inter: "Inter, sans-serif;",
    archivo: "Archivo, sans-serif;"
  },
  fontSize: {
    sm: "0.875em",
    mm: "1em",
    md: "1.25em",
    md2: "1.625em",
    lg: "2.1875em",
    xlg: "2.625em",
    xxlg: "3.75em",
    xxxlg: "6.25em",
    base: "16px"
  },
  fontSizePx: {
    smmm: "10px",
    smm: "12px",
    sm: "14px",
    mm: "16px",
    md: "20px",
    md2: "24px",
    lg: "32px",
    xlg: "42px",
    xxlg: "56",
    xxxlg: "80px",
    base: "16px"
  },
  shadow: {
    vertical: "0px 3px 5px -1px rgba(0, 0, 0, 0.4)",
    bottomRight: "4px 4px 7px 0px rgba(0,0,0,0.4)",
    neomorph: (size: number, blur: number, color: string) => `
      ${size}px ${size}px ${blur}px ${Color(color).darken(0.1)},
     -${size}px -${size}px ${blur}px ${Color(color).lighten(0.3)}
    `
  },
  animation: {
    duration: "0.2s ease-in",
    name: "animatetop"
  },
  devices: {
    mobileS: `(max-width: 320px)`,
    mobileM: `(max-width: 375px)`,
    mobileL: `(max-width: 600px)`,
    tablet: `(max-width: 1023px)`,
    laptop: `(min-width: 1024px)`,
    laptopL: `(max-width: 1440px)`,
    desktop: `(max-width: 2560px)`
  },
  siteWidth: `1440px`,
  borderRadius: `5px`
};

export const ResetCss = createGlobalStyle`

  html {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-display: swap;
  }
  
  body {
    padding: 0;
    margin: 0;
    font-size: ${theme.fontSize.base};
    font-family: 'Poppins', sans-serif;
    
    font-display: swap;
    overflow-x: hidden;
    @media ${theme.devices.tablet} {
      width: 100vw;
    }
  }

  input {
    &:focus {
      outline: none;
    }
    font-family: 'Poppins', sans-serif;
  }
  
  .fade-enter {
    opacity: 0;
    z-index: 1;
  }
  
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }

  .fade-exit {
    opacity: 1;
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
  }

  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 250ms ease-in;
  }

  .slideup-enter {
    opacity: 0;
    transform: translateY(30px);
    z-index: 1;
  }
  
  .slideup-enter.slideup-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 250ms ease-in, transform 250ms ease-in;
  }

  .slideup-exit {
    opacity: 1;
    z-index: 1;
  }

  .slideup-exit.slideup-exit-active {
    opacity: 0;
    transition: opacity 250ms ease-in;
  }


@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
`;
