import styled from "styled-components";
import TerrainIcon from "@material-ui/icons/TerrainTwoTone";
import { Link } from "react-router-dom";

export const Container = styled(Link)<any>`
  display: flex;
  flex-direction: column;
  border: 1px solid #0F0F0F14
  box-sizing: border-box;
  width: ${({ newWidth }) => (newWidth ? "200px" : "100%")};
  flex:1;
  text-decoration: none !important;
  max-width: 210px;
  border-radius: 10px;
  background: #fff;
  cursor: pointer;
`;

export const ProductImage = styled.img`
  height: 134px;
  width: 86%;
  margin: 0 auto;
  display: block;
  object-fit: contain;
`;

export const Title = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #0f0f0f;
  font-family: ${({ theme }) => theme.fonts.inter};
  margin-bottom: 0px;
  a {
    color: inherit; /* Mantém a cor herdada */
    text-decoration: none !important; /* Garante a remoção do sublinhado */
    display: inline-block; /* Para evitar efeitos colaterais de estilos de inline */
  }
`;

export const Category = styled.div`
  display: inline-block;
  background: ${({ theme }) => theme.colors.newGreen.green2};
  color: ${({ theme }) => theme.colors.newGreen.green3};
  padding: 8px 16px;
  border-radius: 4px;
  font-size: ${({ theme }) => theme.fontSizePx.smmm};
  font-weight: 500;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.inter};
  a {
    text-decoration: none;
    color: inherit;
  }
`;

export const SeeMoreInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const LinkSeeMode = styled.a`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.newGreen.green3};
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 17px;
  gap: 8px;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const DividerHorizontal = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.grayNew.gray1};
  background: #0f0f0f14;
`;

export const Unity = styled.p`
  margin: 0;
  padding: 0;

  font-size: ${({ theme }) => theme.fontSizePx.smmm};
  font-weight: 400;
  color: #74787c;
  font-family: ${({ theme }) => theme.fonts.inter};
  text-decoration: none !important;
  display: inline-block;
`;

export const InfoPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
`;

export const PriceActual = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  color: #0f0f0f;
  font-family: ${({ theme }) => theme.fonts.inter};
`;

export const PriceOld = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black.normal};
  font-family: ${({ theme }) => theme.fonts.inter};
  text-decoration: line-through;
`;
