import Routes from "Routes";
import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IMenuItemModel } from "../index";

const menu: IMenuItemModel = {
  name: "Outros",
  link: "",
  icon: <GiHamburgerMenu />,
  submenu: [
    {
      category: "Imóveis Rurais",
      link: Routes.properties.replace(":category?", "")
    },
    {
      category: "Veículos",
      link: Routes.vehicles.replace(":subcategory?", "")
    },
    {
      category: "Vagas",
      link: Routes.jobs
    },
    {
      category: "Arrendamentos",
      link: Routes.leases.replace(":category?", "")
    },
    {
      category: "Serviços",
      link: Routes.services
    }
  ]
};

export default menu;
