import React, {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import { useSelector } from "react-redux";
import { ReducerState } from "Redux/store";

import * as Styled from "./styles";

interface Props {
  children: any;
  size: number;
  autoPlay?: boolean;
  interval?: number;
  edit?: boolean;
  scrolling?: boolean;
  setImages?(files: any): void;
  setDelete?(id: SetStateAction<string[]>): void;
}

const Carousel: React.FC<Props> = ({
  children,
  size,
  autoPlay = false,
  interval = 30000,
  edit = false,
  scrolling = false,
  setImages,
  setDelete
}) => {
  const mobile = useSelector((state: ReducerState) => state.locale.mobile);
  const fileInput = useRef(null);
  const [images, setPreviewImages] = useState<string[]>([]);
  const length = edit ? children.length + 1 + images.length : children.length;
  const [count, setCount] = useState(edit ? Math.floor(length / size) : 0);

  const next = useCallback(() => {
    count < length - size + images.length
      ? setCount(state => state + 1)
      : setCount(0);
  }, [count, images.length, length, size]);

  const prev = () => {
    count > 0 && setCount(state => state - 1);
  };

  useEffect(() => {
    if (autoPlay) {
      const loop = setInterval(() => next(), 30000);

      return () => clearInterval(loop);
    }
  }, [autoPlay, next]);

  const onChangeHandler = (e: any) => {
    const files: FileList = e.target.files;
    Array.from(files).forEach((file: File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setPreviewImages(prev =>
          reader.result ? [...prev, reader.result.toString()] : prev
        );
      };
    });
    setImages && setImages((prev: File[]) => [...prev, ...Array.from(files)]);
    length - size + files.length > 0 &&
      setCount((prev: number) => prev + files.length);
  };

  const removeNewImage = (index: number) => {
    setPreviewImages(state => state.filter((item, key) => key !== index));
    setImages &&
      setImages((state: File[]) => state.filter((item, key) => key !== index));
    count > 0 && setCount((prev: number) => prev - 1);
  };

  const deleteItem = (id: string) => {
    if (setDelete) {
      setDelete(state => [...state, id]);
      count > 0 && setCount((prev: number) => prev - 1);
    }
  };

  if (!children.length) {
    return children;
  }

  return (
    <Styled.CarouselContainer>
      <Styled.CarouselBox>
        <Styled.Container className="carousel-container" scrolling={scrolling}>
          <Styled.Wrap
            className="carousel-content"
            translate={count * (100 / size)}
          >
            {children.map((child: ReactElement, key: number) => (
              <Styled.Item
                className="carousel-item"
                key={`carousel-${key}`}
                size={Math.floor(100 / size)}
              >
                {setDelete && edit && (
                  <Styled.DeleteItem
                    onClick={() =>
                      child.props.delete && deleteItem(child.props.delete)
                    }
                  />
                )}
                {child}
              </Styled.Item>
            ))}
            {edit && (
              <>
                {images.map((image: string, key: number) => (
                  <Styled.Item
                    key={`carousel-new-${key}`}
                    size={mobile ? 100 / size - 5 : 100 / size}
                  >
                    <Styled.DeleteItem onClick={() => removeNewImage(key)} />
                    <Styled.Image src={image} />
                  </Styled.Item>
                ))}
                <Styled.Item size={100 / size}>
                  <Styled.NewItems
                    onClick={() => {
                      const node: HTMLElement = fileInput.current!;
                      node && node.click();
                    }}
                  />
                  <Styled.Input
                    ref={fileInput}
                    type="file"
                    name="files"
                    multiple
                    onChange={onChangeHandler}
                  />
                </Styled.Item>
              </>
            )}
          </Styled.Wrap>
          {length && !scrolling && (
            <>
              {count !== 0 && (
                <Styled.NavigationIcon position="left" onClick={prev}>
                  <FaChevronLeft size={25} />
                </Styled.NavigationIcon>
              )}
              {count !== length - size && length - size > 0 && (
                <Styled.NavigationIcon position="right" onClick={next}>
                  <FaChevronRight size={25} />
                </Styled.NavigationIcon>
              )}
            </>
          )}
        </Styled.Container>
      </Styled.CarouselBox>
    </Styled.CarouselContainer>
  );
};

export default Carousel;
