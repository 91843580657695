import styled from "styled-components";
import { theme } from "theme/theme";

const green = theme.colors.green.normal;
const white = theme.colors.white.normal;
const black = theme.colors.black.normal;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;
  color: ${black};

  margin-top: 16px;

  font-size: ${({ theme }) => theme.fontSize.md};
`;

interface ButtonProps {
  isCurrent?: boolean;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border: none;
  border: 0.05rem solid ${({ isCurrent }) => (isCurrent ? green : "#E9E9E9")};
  border-radius: 4px;
  background-color: ${({ isCurrent }) => (isCurrent ? green : white)};
  color: ${({ isCurrent }) => (isCurrent ? white : "#4a4a4a")};
  cursor: pointer;
  font-family: "Inter", "sans-serif";
  font-size: 14px;
  font-weight: 500;
  :hover {
    background-color: ${green};
    color: ${white};
  }
  :hover img {
    filter: brightness(0) invert(1);
  }
`;

export const Text = styled.p`
  margin: 0rem;
  cursor: default;
`;
