import { useQuery } from "@tanstack/react-query";
import listSubCategories from "Api/categories/subcategories";
import { IOptions } from "Components/Form/Inputs/CheckboxSearch";
import arrayToString from "Helpers/arrayToString";
import { listSubCategoriesRequest } from "Services/Category";
import { TError } from "Services/Error/error";
import { useParams } from "react-router-dom";

interface UseSubCategoriesProps extends listSubCategoriesRequest {
  selectedSubcategories: IOptions[];
}

type Params = {
  subcategory: string;
};

export default function useSubCategories({
  selectedSubcategories,
  ...queryParams
}: UseSubCategoriesProps) {
  const { subcategory: subCategoryParams } = useParams<Params>();
  const query = () => listSubCategories(queryParams);

  const { data: subCategories, isError } = useQuery({
    queryKey: ["listSubCategories", ...Object.values(queryParams)],
    queryFn: query,
    keepPreviousData: true
  });

  const subCategoriesError: TError = {
    status: isError,
    message: isError ? "Erro no servidor, tente mais tarde" : ""
  };

  const subCategoriesOptions = subCategories
    ? subCategories.map(
        (item): IOptions => ({
          id: item.id,
          name: item.title,
          subvalue: item.ct,
          qty: item.adsQty
        })
      )
    : // .filter(
      //   category =>
      //     selectedSubcategories.filter(item => item.id === category.id)
      //       .length === 0
      // )
      [];

  const subcategoryByUrl =
    subCategories &&
    subCategories.find(item => item.slug === subCategoryParams);

  const subCategoriesFilter = subcategoryByUrl
    ? subcategoryByUrl.id
    : arrayToString(selectedSubcategories);

  return {
    subCategoriesOptions,
    subCategoriesFilter,
    subCategoriesError,
    subcategoryByUrl
  };
}
