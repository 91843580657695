import bannerDelete from "Api/banners/delete";
import ButtonPrimary from "Components/Buttons/Primary";
import localeActions from "Redux/Locale/actions";
import { TBannerResponse } from "Services/Banners/banner";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import moment from "moment";
import React from "react";
import { registerPlugin } from "react-filepond";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Styled from "./styles";

interface BannerItemProps {
  readonly banner: TBannerResponse;
  readonly onBannerChange: () => void; // Adicionando a propriedade onBannerChange
}

export type TBannerType = {
  name: string;
};

registerPlugin(FilePondPluginImageExifOrientation);

export default function BannerItem({
  banner,
  onBannerChange,
  ...rest
}: BannerItemProps) {
  const history = useHistory();

  const dispatch = useDispatch();

  const {
    id,
    title,
    description,
    campaign_code,
    position,
    start_at,
    expires_at,
    enabled,
    image,
    created_at,
    updated_at
  } = banner;

  const handleDeleteBanner = async () => {
    try {
      if (!window.confirm("Deseja realmente excluir este banner?")) {
        return;
      }
      await bannerDelete(id);
      dispatch(
        localeActions.addTooltip({
          type: "success",
          message: "Excluído com sucesso!"
        })
      );
      onBannerChange();
    } catch (error) {
      dispatch(
        localeActions.addTooltip({
          type: "error",
          message: "Erro ao tentar excluir banner: " + JSON.stringify(error)
        })
      );
    }
  };

  return (
    <Styled.BannerItem key={`banner`} {...rest}>
      <Styled.DataImage>
        <Styled.ImageContainer>
          <img src={image} alt={image} width={200} />
          <a href={image} rel="noreferrer" target="_blank">
            {image}
          </a>
        </Styled.ImageContainer>
      </Styled.DataImage>

      <Styled.BannerInfo>
        <Styled.BannerTitle>{title}</Styled.BannerTitle>
        <Styled.Content>
          <Styled.Data>
            <Styled.Date>
              Descrição:&nbsp;<strong>{description}</strong>
            </Styled.Date>
            <Styled.Date>
              Código da campanha:&nbsp;<strong>{campaign_code}</strong>
            </Styled.Date>
            <Styled.Date>
              Posição:&nbsp;<strong>{position}</strong>
            </Styled.Date>
            <Styled.Date>
              Inicia em:&nbsp;<strong>{formatDate(start_at)}</strong>
            </Styled.Date>
            <Styled.Date>
              Termina em:&nbsp;<strong>{formatDate(expires_at)}</strong>
            </Styled.Date>
            <Styled.Date>
              Ativo:&nbsp;<strong>{enabled ? "Sim" : "Não"}</strong>
            </Styled.Date>
            <Styled.Date>
              Criado em:&nbsp;<strong>{formatDate(created_at)}</strong>
            </Styled.Date>
            <Styled.Date>
              Atualizado em:&nbsp;<strong>{formatDate(updated_at)}</strong>
            </Styled.Date>
          </Styled.Data>
          <Styled.Buttons>
            <ButtonPrimary
              color="green"
              action={() => history.push(`/admin/banners/edit/${id}`)}
            >
              <FaEdit /> Editar
            </ButtonPrimary>
            <ButtonPrimary color="orange" action={() => handleDeleteBanner()}>
              <MdDelete />
              Excluir
            </ButtonPrimary>
          </Styled.Buttons>
        </Styled.Content>
      </Styled.BannerInfo>
    </Styled.BannerItem>
  );
}

function formatDate(isoDateString: string): string {
  return moment(isoDateString).format("DD/MM/YYYY - hh:mm");
}
