import styled from "styled-components";
import InputMask from "react-input-mask";
import { FaSearch } from "react-icons/fa";
interface IField {
  valid: boolean;
}

interface IMinHeightProps {
  min_height?: string;
}

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: unset;
  outline: #e6e6e6 solid 1px;
  flex-grow: 1;
  display: flex;
  width: 95%;
`;

export const Fieldset = styled.fieldset<IField>`
  border: none;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  display: flex;
  flex-direction: column;

  input,
  textarea {
    border-bottom: 1px solid #E2E8F0;
    color: #2D3748
    font-weight: medium;
    &:placeholder {
      color: #718096;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;
    } 
  }
`;

export const Label = styled.label<IMinHeightProps>`
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: ${({ theme }) => theme.fontSize.mm};
  letter-spacing: 2px;
  font-weight: 600;
  padding-bottom: 10px;
  min-height: ${props => (props.min_height ? props.min_height : "0px")};
`;

export const RowDiv = styled.div`
  display: flex;
`;

export const DetailsLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: 500;
  padding-bottom: 15px;
`;

export const InputMaskHtml = styled(InputMask)``;

export const Input = styled.input`
  width: 100%;
  height: 2.2rem;
  margin-bottom: 0.3rem;
  font-size: 1rem;
  padding-inline: 0px;
  border-radius: 0px;
  min-width: 0px;
  border: none;
  outline: transparent solid 2px;
  outline-offset: 2px;
  position: relative;
  appearance: none;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms
  --input-padding: var(--chakra-space-4);
  --input-border-radius: var(--chakra-radii-md);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #E2E8F0;
  background: transparent;


  &:focus-visible {
    border-color: ${({ theme }) => theme.colors.green.normal};
    box-shadow:  ${({ theme }) => theme.colors.green.normal} 0px 1px 0px 0px;
  }

  &:[data-focus-visible] {
    border-color: ${({ theme }) => theme.colors.green.normal};
    box-shadow: ${({ theme }) => theme.colors.green.normal} 0px 1px 0px 0px;
  }  
`;
export const InputNew = styled.input`
  border: none;
  flex-grow: 1;
  padding-left: 10px;
  color: #02720c;
  background-color: transparent;
  width: 100%;
`;

interface IError {
  valid: boolean;
}

export const Error = styled.span<IError>`
  color: ${({ theme, valid }) =>
    valid ? theme.colors.gray.dark : theme.colors.red.normal};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: ${({ theme }) => theme.fontSize.sm};
  padding-top: 5px;
  padding-bottom: 10px;
  height: 10px;
  text-align: right;
`;

export const Textarea = styled.textarea`
  border: none;
  font-family: ${({ theme }) => theme.fonts.inter};
  background: transparent;
  padding-bottom: 10px;
  font-size: ${({ theme }) => theme.fontSize.sm};
  width: 100%;
  transition: border-bottom ${({ theme }) => theme.animation.duration};
  height: 100px;
  line-height: 18px;
  resize: vertical;
  &:focus {
    outline: none;
  }
`;

export const SearchButton = styled.button`
  background-color: #ffffff;
  padding: 0;
  border: none;
  margin: 0;
  font-weight: 600;
  font-size: 10px;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
`;

export const SearchIcon = styled(FaSearch).attrs(() => ({
  icon: FaSearch
}))`
  font-size: 19px;
  padding: 7px;
  margin: 0 auto;
  color: #58595b;
`;
