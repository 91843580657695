import styled from "styled-components";

export const Field = styled.fieldset`
  border: none;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
`;

export const Title = styled.label`
  display: flex;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: 600;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.green.dark};
  margin-bottom: 20px;
`;

export const Labels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Label = styled.span`
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.green.dark};
  font-weight: 600;
  letter-spacing: 1px;
  font-size: ${({ theme }) => theme.fontSize.sm};
  margin-right: 10px;
`;
