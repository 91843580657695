import CheckboxSearch, {
  IOptions
} from "Components/Form/Inputs/CheckboxSearch";
import { createTrack } from "Helpers/tracking";
import localeActions from "Redux/Locale/actions";
import { ReducerState } from "Redux/store";
import { TError } from "Services/Error/error";
import { TSegmentsName } from "Services/Segment/segment";
import { IAdTracking } from "Services/Tracking/tracking";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

interface Props {
  subcategorySearch: string;
  setSubCategorySearch(text: string): void;
  subcategoriesError: TError | undefined;
  selectedSubcategories: IOptions[];
  subcategoryOptions: IOptions[];
  setSelectedSubcategories(selected: any): void;
  segment: {
    name: TSegmentsName;
    subvalue: string;
  };
  initialSubCategoryOptions?: any[];
}

export const Subcategories: React.FC<Props> = ({
  subcategorySearch,
  segment,
  selectedSubcategories,
  setSelectedSubcategories,
  setSubCategorySearch,
  subcategoriesError,
  subcategoryOptions,
  initialSubCategoryOptions = []
}) => {
  // const { user, token } = useSelector((state: ReducerState) => state.auth);
  // const { gaId } = useSelector((state: ReducerState) => state.locale);
  // const location = useLocation();
  // const dispatch = useDispatch();

  // const [previous, setPrevious] = useState<IOptions[] | null>(null);
  // function reorderOptions(
  //   selectedSubCategories: any[],
  //   subCategoryOptions: IOptions[]
  // ) {
  //   if (selectedSubCategories.length == 0) return subCategoryOptions;
  //   const options = [...initialSubCategoryOptions].filter(item => {
  //     return subcategorySearch
  //       ? item.name.toLowerCase().includes(subcategorySearch.toLowerCase())
  //       : true;
  //   });
  //   selectedSubCategories.forEach(category => {
  //     if (
  //       category.indice !== undefined &&
  //       category.indice >= 0 &&
  //       category.indice < options.length
  //     ) {
  //       options[category.indice] = category;
  //     } else {
  //       options.push(category);
  //     }
  //   });
  //   const remainingOptions = subCategoryOptions.filter(
  //     option =>
  //       !options.some(
  //         (selected: { id: string | number }) => selected.id === option.id
  //       )
  //   );
  //   return options.concat(remainingOptions);
  // }

  // useEffect(() => {
  //   if (selectedSubcategories.length > 0) {
  //     const newEvent = selectedSubcategories
  //       .filter(subcategory => {
  //         if (
  //           previous &&
  //           previous.some(value => value.subvalue === subcategory.subvalue)
  //         ) {
  //           return false;
  //         }

  //         return true;
  //       })
  //       .reduce((previous, current) => {
  //         const event: IAdTracking = {
  //           TYPE: "CATEGORY_CLICK",
  //           GA_ID: gaId,
  //           USER_ID: user ? user.id : "",
  //           DATA: {
  //             CATEGORY: ``,
  //             SUBCATEGORY: `${segment.subvalue}/${current.subvalue}`
  //           },
  //           PAGE_REF: location.pathname,
  //           TAGS: "pos=menu"
  //         };

  //         return event;
  //       }, {} as IAdTracking);

  //     if (Object.keys(newEvent).length > 0) {
  //       dispatch(
  //         localeActions.addTracking(createTrack(newEvent, segment.name))
  //       );
  //     }

  //     setPrevious(selectedSubcategories);
  //   } else {
  //     setPrevious(null);
  //   }
  // }, [selectedSubcategories]);

  return (
    <CheckboxSearch
      label=""
      search={subcategorySearch}
      setSearch={setSubCategorySearch}
      options={subcategoryOptions}
      selected={selectedSubcategories}
      setSelected={setSelectedSubcategories}
      placeholder="Pesquisar. ."
      showQty={true}
      newSearch={true}
      error={subcategoriesError}
    />
  );
};
