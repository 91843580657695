import { useQuery } from "@tanstack/react-query";
import fetchProduct, { FetchProductRequest } from "Api/products/[slug]";
import listProductsInGroup from "Api/productsGroup/listProducts/[id]";
import { queryClient } from "App";
import { useSelector } from "react-redux";
import { ReducerState } from "Redux/store";
import { IAd } from "Services/Ad/ad";

interface UseProductProps extends FetchProductRequest {}

//Keep cache for 3 minutes
const staleTime = 60 * 3 * 1000;

export default function useProduct(params: UseProductProps) {
  const { gaId } = useSelector((state: ReducerState) => state.locale);
  const { user } = useSelector((state: ReducerState) => state.auth);

  const { data, ...rest } = useQuery({
    queryKey: ["fetchProduct", ...Object.values(params)],
    queryFn: () => fetchProduct(params),
    keepPreviousData: true,
    staleTime
  });

  const groupId = data && data.group_id ? data.group_id : "";

  const { data: group } = useQuery({
    queryKey: ["listProductsInGroup", groupId],
    queryFn: () => listProductsInGroup({ groupId }),
    keepPreviousData: true,
    enabled: !!groupId,
    staleTime
  });

  const productWithShipping =
    !!(
      data &&
      data.address &&
      // data.price_per_kilometer > 0 &&
      (data.national_ship || data.shippingStates.length > 0)
    ) ||
    !!(
      data &&
      data.address &&
      data.weight &&
      data.height &&
      data.width &&
      data.length
    );

  const relatedProducts =
    data &&
    group &&
    group.filter(item => item.product_id !== data.id).map(item => item.product);

  const prefetchRelatedProducts = async (relatedProducts: IAd[]) => {
    relatedProducts
      .filter((product, key) => key < 3)
      .map(product => {
        return {
          slug: product.slug,
          userId: user && user.id,
          gaId: gaId && gaId
        };
      })
      .forEach(product =>
        queryClient.prefetchQuery({
          queryKey: ["fetchProduct", ...Object.values(product)],
          queryFn: () => fetchProduct({ ...product, segment: params.segment }),
          staleTime
        })
      );
  };

  relatedProducts &&
    relatedProducts.length > 0 &&
    prefetchRelatedProducts(relatedProducts);

  return { data, productWithShipping, relatedProducts, ...rest };
}
