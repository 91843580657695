import React from "react";
import NotFoundImage from "../../../Assets/Images/not-found.jpg";
import { useHistory } from "react-router-dom";
import * as Styled from "./styles";
import stringPrice from "Helpers/stringPrice";

interface Props {
  title: string;
  category: string;
  subcategory: string;
  image: string;
  slug: string;
  price?: any;
  oldPrice?: any;
  link: any;
  stock?: any;
  newWidth?: any;
  flex?: any;
}

const AdListItem: React.FC<Props> = ({
  title,
  category,
  image,
  subcategory,
  slug,
  price,
  oldPrice,
  link,
  stock,
  newWidth,
  flex
}) => {
  return (
    <Styled.Container newWidth={newWidth} to={link}>
      <div style={{ flex: 1, marginTop: 10 }}>
        {image ? (
          <Styled.ProductImage src={image} alt="Product image" />
        ) : (
          <Styled.ProductImage src={NotFoundImage} alt="not found image" />
        )}
        {category && (
          <Styled.Category
            style={{ marginLeft: 14, marginRight: 14, marginTop: 14 }}
          >
            <a href={`/produtos/`}>{`${
              category.length + subcategory.length > 32
                ? `${category.slice(0, 15)}...`
                : category
            }`}</a>
          </Styled.Category>
        )}
        <Styled.Title
          style={{ marginLeft: 14, marginRight: 14, marginTop: 10 }}
        >
          <a
            style={
              flex
                ? {
                    textDecoration: "none",
                    overflow: "hidden",
                    display: "block",
                    width: "100%"
                  }
                : {
                    textDecoration: "none",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                    width: "100%"
                  }
            }
            href={`/produto/${slug}`}
          >
            {`${
              title.length > 18 && flex ? `${title.substring(0, 15)}...` : title
            }`}
          </a>
        </Styled.Title>
        <Styled.Unity style={{ marginLeft: 14, marginRight: 14 }}>
          {stock ? `${stock} no estoque` : ""}
        </Styled.Unity>
        <Styled.InfoPrice style={{ marginLeft: 14, marginRight: 4 }}>
          <Styled.PriceActual>
            {price ? `${stringPrice(price / 100)}` : "Sob Consulta"}
          </Styled.PriceActual>
          {/* {oldPrice && (
            <Styled.PriceOld>{`${stringPrice(
              503433400 / 100
            )}`}</Styled.PriceOld>
          )} */}
        </Styled.InfoPrice>
      </div>
      <Styled.SeeMoreInformation style={{ marginTop: 24 }}>
        <Styled.DividerHorizontal />
        <div
          style={{
            width: "100%",
            height: "36px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Styled.LinkSeeMode href={`/produto/${slug}`}>
            VER DETALHES
          </Styled.LinkSeeMode>
        </div>
      </Styled.SeeMoreInformation>
    </Styled.Container>
  );
};

export default AdListItem;
