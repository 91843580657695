import api from "Helpers/Api";

export default async function bannerUpdateImage(
  bannerId: string,
  formData: FormData
) {
  const { data } = await api.put<void>(`/banners/${bannerId}/image`, formData);

  return data;
}
