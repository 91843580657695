import styled from "styled-components";
import CheckIcon from "@material-ui/icons/Check";
import InputMask from "react-input-mask";
interface IField {
  status: boolean;
  disabled?: boolean;
}
export const Field = styled.div<IField>`
  border: none;
  flex: 1;
  display: flex;
  margin: 0;
  padding-top: 6px;
  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;
export const CheckBox = styled.input`
  display: none;
`;
export const CheckBoxContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
export const Check = styled.div<IField>`
  border-radius: 20px;
  flex-direction: row;
  width: 18px;
  height: 18px;
  align-items: center;
  border: 1px solid #E2E2E2
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: opacity ${({ theme }) => theme.animation.duration};
  transition: background-color ${({ theme }) => theme.animation.duration};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  background-color: ${({ theme, status }) =>
    status ? theme.colors.green.normal : "transparent"};
  color: ${({ theme }) => theme.colors.white.normal};
`;
export const Content = styled.span<IField>`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-weight: 400;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  white-space: normal;
  word-break: break-word;
  margin-left: 8px;
  flex: 1;
`;
interface IError {
  valid: boolean;
}
export const Error = styled.span<IError>`
  color: ${({ theme, valid }) =>
    valid ? theme.colors.red.normal : theme.colors.gray.dark};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: ${({ theme }) => theme.fontSize.sm};
  padding-top: 3px;
  margin-left: 10px;
  height: 10px;
`;
export const InputMaskHtml = styled(InputMask)``;
export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: ${({ theme }) => theme.fontSize.mm};
  letter-spacing: 2px;
  font-weight: 600;
  padding-bottom: 10px;
`;
export const DetailsLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: 500;
  text-align: left;
`;
