import React, { useState } from "react";
import { NavLink, Route } from "react-router-dom";
import Routes from "Routes";
import FooterTop from "../../../Assets/Images/footer-top.png";
import LogoFacebook from "../../../Assets/Images/Icons/facebook.png";
import LogoInstagram from "../../../Assets/Images/Icons/instagram.png";
import { FooterMenuItems } from "../Header/Menu";
import Partners from "./Partners";
import * as Styled from "./styles";

const Footer: React.FC = () => {
  const [showSpan, setShowSpan] = useState<boolean>(false);

  return (
    <>
      {/* <Styled.BackTop
        onClick={() => {
          scrollTo(0);
        }}
      >
        {showSpan && (
          <Styled.BackTopSpanContainer>
            Voltar ao topo
          </Styled.BackTopSpanContainer>
        )}
        <Styled.ArrowContainer
          onMouseEnter={() => setShowSpan(true)}
          onMouseLeave={() => setShowSpan(false)}
        >
          <Styled.ArrowUp />
        </Styled.ArrowContainer>
      </Styled.BackTop> */}

      <Styled.FooterLogo src={FooterTop} alt="Topo do footer" />
      <Styled.Footer>
        <Styled.ColumnsContainer>
          <Styled.Container>
            {FooterMenuItems.map((menuItem, menuKey) => (
              <Styled.ContainerContent key={menuKey}>
                <Styled.ColumnTitle>{menuItem.name}</Styled.ColumnTitle>
                {menuItem.submenu &&
                  menuItem.submenu.map((subMenuItem, subMenuKey) => (
                    <Styled.SubMenu key={subMenuKey}>
                      <NavLink exact to={subMenuItem.link || "#"}>
                        {subMenuItem.category}
                      </NavLink>
                    </Styled.SubMenu>
                  ))}
              </Styled.ContainerContent>
            ))}
            <Styled.SocialMedia>
              <p>Redes Sociais</p>
              <Styled.SocialMediaIcons>
                <Styled.SocialMediaIcon
                  target="_blank"
                  href="https://www.instagram.com/ruraxoficial/"
                  className="facebook-rurax"
                >
                  <Styled.LogoSocialMedia
                    src={LogoInstagram}
                    alt="Logo do Instagram"
                  />
                </Styled.SocialMediaIcon>
                <Styled.SocialMediaIcon
                  target="_blank"
                  href="https://www.facebook.com/ruraxoficial"
                  className="instagram-rurax"
                >
                  <Styled.LogoSocialMedia
                    src={LogoFacebook}
                    alt="Logo do Facebook"
                  />
                </Styled.SocialMediaIcon>
                {/* <Styled.SocialMediaIcon>
                  <GrFacebookOption />
                </Styled.SocialMediaIcon> */}
              </Styled.SocialMediaIcons>
            </Styled.SocialMedia>
            {/* <Styled.DividerVertical /> */}
            <Partners />
          </Styled.Container>
        </Styled.ColumnsContainer>
      </Styled.Footer>
    </>
  );
};

export default Footer;
